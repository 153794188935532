import ConfigureService from "@/services/configureService";

export default class OverviewService extends ConfigureService {
  constructor() {
    super("resumo");
  }

  getOverview({ region, championship }) {
    return this.apiStats.get(`${region}/${championship}/resumo`);
  }

  getRecurrence({ region, championship }) {
    return this.apiStats.get(`${region}/${championship}/recorrencia`);
  }
}
