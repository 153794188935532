<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section
        class="modal-card-body"
        style="overflow-y: auto; max-height: 700px"
      >
        <div v-if="info.length > 0" class="columns is-multiline is-gapless">
          <div
            v-for="(kps, i) of info"
            :key="'kpi-det-' + i"
            class="column is-full"
          >
            <div class="block-details">
              <div class="level is-mobile" style="padding-bottom: 0">
                <div class="level-left">
                  <div class="kps-foto">
                    <img :src="kps.atleta.foto" />
                  </div>
                </div>

                <div class="level-item" style="display: block">
                  <span style="display: block">{{ kps.atleta.apelido }}</span>
                  <small
                    style="display: block; opacity: 0.4; font-size: 0.8em"
                    >{{ kps.atleta.posicao.toUpperCase() }}</small
                  >
                </div>

                <div class="level-right" style="display: block">
                  <small style="display: block; text-align: right"
                    >{{ kps.rodada }}ª Rodada -
                    {{ kps.dados.minuto }} min.</small
                  >
                  <div class="level is-mobile" style="margin-bottom: 0;">
                    <div class="level-item">
                      <span>{{
                        kps.conquistado.mando == "casa"
                          ? kps.conquistado.sigla
                          : kps.cedido.sigla
                      }}</span>
                    </div>
                    <div class="level-item">
                      <b>X</b>
                    </div>
                    <div class="level-item">
                      <div style="display: block">
                        <span>{{
                          kps.conquistado.mando == "fora"
                            ? kps.conquistado.sigla
                            : kps.cedido.sigla
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="level is-mobile">
                <div class="level-item">
                  <div class="centralize">
                    <label style="opacity: 0.5; font-size: 0.9em">Tipo</label>
                    <p>{{ kps.dados.tipo_jogada }}</p>
                  </div>
                </div>
                <div class="level-item">
                  <div class="centralize">
                    <label style="opacity: 0.5; font-size: 0.9em">Bola</label>
                    <p>{{ kps.dados.bola }}</p>
                  </div>
                </div>
                <div class="level-item" :style="{ maxWidth: '45%' }">
                  <div class="centralize">
                    <label style="opacity: 0.5; font-size: 0.9em">Área</label>
                    <p style="text-align: center">
                      {{ kps.dados.nome_setor_conquistado }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Sem Registros.</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Detalhes",
  props: {
    info: {
      type: [Object, Array],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.block-details {
  border-radius: 4px;
  margin: 0.25em;
  padding: 0.5em;
  border: 1px solid transparent;
  background-color: #4c516d;
}
.kps-foto {
  height: 40px;
  width: 40px;
  margin-right: 0.5em;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  border: 1px solid white;
}
</style>
