<template>
  <BaseLayout
    :championships="championships"
    :loading="isLoading"
    @change-championship="handleChangeChampionship"
    @click="handlerEveryClick"
  >
    <BasePage
      id="athletes"
      title="Atletas"
      subtitle="Lista de todos os atletas com informações detalhadas"
    >
      <template slot="options">
        <div class="options-groups-scouts">
          <b-field>
            <b-radio-button
              v-for="group of scoutsGroups"
              :key="`scout-group-${group.id}`"
              v-model="params.groupScouts"
              :native-value="group.id"
              type="is-independence"
              :size="isMobile ? 'is-small' : ''"
              @input="handleGroupScout"
            >
              {{ group.descricao }}
            </b-radio-button>
          </b-field>
        </div>
        <FilterAthletes
          ref="filterAtletas"
          :filters-params="filterParams"
          :matches="matches"
          :is-brazil-championship="isBrazilChampionship"
          @result="(filter) => handleFilter(filter)"
        ></FilterAthletes>
      </template>

      <div class="table-container">
        <div class="field has-addons" v-if="isMobile">
          <b-select
            v-model="params.orderColumn"
            expanded
            @input="searchAthletes"
          >
            <option
              v-for="column of sortableColumns"
              :key="`sort-key-${column.field}`"
              :value="column.field"
              >{{ column.label }}</option
            >
          </b-select>
          <div class="control">
            <button class="button is-primary" @click="changeSort">
              <b-icon
                :class="{ 'is-desc': !isAsc }"
                :icon="sortIcon"
                pack="fas"
                size="is-small"
                both
              />
            </button>
          </div>
        </div>

        <b-table
          v-if="!isLoadingAthletes"
          blueDark
          athletes
          :data="athletes"
          mobile-cards
          backend-pagination
          :backend-sorting="!isMobile"
          paginated
          narrowed
          pagination-position="bottom"
          pagination-simple
          :per-page="params.limit"
          :current-page="params.page"
          :total="count"
          default-sort-direction="desc"
          :default-sort="!isMobile ? ['preco_atual', 'desc'] : null"
          :selected.sync="athleteSelected"
          @sort="handleSort"
          @page-change="changePage"
        >
          <template slot="header" slot-scope="props">
            <div v-if="props.column.sortable" class="centralize-row">
              <b-icon
                pack="fas"
                :icon="handleSortColumn(props.column)"
                size="is-small"
              ></b-icon>
              {{ props.column.label }}
            </div>
            <div v-else>
              {{ props.column.label }}
            </div>
          </template>

          <template slot-scope="props">
            <b-table-column :visible="isMobile">
              <div style="width: 100%">
                <div>
                  <PlayerColumnMobile
                    :player="props.row"
                    :groupScouts="params.groupScouts"
                  ></PlayerColumnMobile>
                </div>

                <div v-if="isBrazilChampionship" style="padding-top: 10px">
                  <RestrictAccess
                    v-if="
                      athleteSelected !== null &&
                        athleteSelected.id === props.row.id
                    "
                    slug="atletas.details"
                    style="width: 100%"
                  >
                    <div
                      class="align-bts-table"
                      style="display: inline-flex; align-items: center; width: 100%"
                    >
                      <b-button
                        expanded
                        type="is-info"
                        icon-pack="fas"
                        size="is-small"
                        icon-left="info"
                        @click="handleAthleteDetails(props.row.id)"
                      >
                        Ver detalhes do atleta
                      </b-button>
                    </div>
                  </RestrictAccess>
                </div>
                <div v-else style="padding-top: 10px">
                  <div
                    v-if="
                      athleteSelected !== null &&
                        athleteSelected.id === props.row.id
                    "
                    class="align-bts-table"
                    style="display: inline-flex; align-items: center; width: 100%"
                  >
                    <b-button
                      expanded
                      outlined
                      disabled
                      type="is-info"
                      size="is-small"
                      style="height: 45px"
                    >
                      Detalhes está disponível apenas para o <br />Campeonato
                      Brasileiro
                    </b-button>
                  </div>
                </div>
              </div>
            </b-table-column>

            <b-table-column :visible="!isMobile" field="nome" label="Atleta">
              <div class="grid-player">
                <div class="player-team-shield">
                  <img
                    :src="props.row.clube.escudo"
                    :alt="props.row.clube.nome"
                  />
                </div>
                <div class="player-image">
                  <img :src="props.row.foto" />
                </div>

                <div class="player-info">
                  <div style="display: flex">
                    <p>{{ props.row.nome }}</p>
                    <img
                      v-if="hasIconStatusPlayer(props.row)"
                      :src="getStatusPlayer(props.row.status)"
                      class="player-status"
                    />
                  </div>
                  <p class="player-subtitle">
                    {{ props.row.posicao.descricao }}
                  </p>
                </div>
              </div>
            </b-table-column>

            <b-table-column :visible="!isMobile" label="" width="50">
              <RestrictAccess
                v-if="isBrazilChampionship"
                slug="atletas.details"
                style="width: 100%"
              >
                <div
                  class="align-bts-table"
                  style="display: inline-flex; align-items: center;"
                >
                  <b-button
                    type="is-info"
                    icon-pack="fas"
                    size="is-small"
                    icon-right="info"
                    outlined
                    @click="handleAthleteDetails(props.row.id)"
                  >
                  </b-button>
                </div>
              </RestrictAccess>

              <div v-else>
                <b-tooltip
                  type="is-pigeon"
                  label="Detalhes de atletas está disponível apenas para o Campeonato Brasileiro"
                >
                  <b-button
                    type="is-info"
                    icon-pack="fas"
                    size="is-small"
                    icon-right="info"
                    outlined
                    disabled
                  >
                  </b-button>
                </b-tooltip>
              </div>
            </b-table-column>

            <b-table-column
              :visible="!isMobile && isBrazilChampionship"
              field="preco_atual"
              label="Preço"
              :sortable="!isMobile"
              numeric
              width="30"
            >
              C$ {{ props.row.preco | decimals }}
            </b-table-column>

            <b-table-column
              :visible="
                !isMobile && isBrazilChampionship && params.groupScouts === 1
              "
              field="valorizacao"
              label="Valorização"
              width="30"
              :sortable="!isMobile"
              numeric
            >
              <b-tag :type="getTypeByValue(props.row.valorizacao)">{{
                props.row.valorizacao | decimals
              }}</b-tag>
            </b-table-column>

            <b-table-column
              :visible="
                !isMobile && isBrazilChampionship && params.groupScouts === 1
              "
              field="min_para_valorizar"
              label="Mínimo Valorizar"
              width="30"
              :sortable="!isMobile"
              numeric
            >
              {{ props.row.valorizacaoMinima | decimals }}
            </b-table-column>

            <b-table-column
              :visible="!isMobile && params.groupScouts === 1"
              label="Média Mando"
              width="50"
              numeric
            >
              <div class="media-value-content">
                {{ formatMediaMando(props.row) | decimals }}
                <small
                  >{{ props.row.partidas[props.row.mandoDeJogo] }} jogos</small
                >
              </div>
            </b-table-column>

            <b-table-column
              :visible="!isMobile && params.groupScouts === 1"
              field="media_pnt_basica"
              label="Média Básica"
              width="20"
              :sortable="!isMobile"
              numeric
            >
              <div class="media-value-content">
                {{ props.row.media.basica | decimals }}
                <small>{{ props.row.partidas.total }} jogos</small>
              </div>
            </b-table-column>

            <b-table-column
              :visible="!isMobile && params.groupScouts === 1"
              field="media_pnt"
              label="Média Total"
              width="20"
              :sortable="!isMobile"
              numeric
            >
              <div class="media-value-content">
                {{ props.row.media.total | decimals }}
                <small>{{ props.row.partidas.total }} jogos</small>
              </div>
            </b-table-column>

            <b-table-column
              :visible="!isMobile && !hideScoutsGeneral"
              v-for="scout of props.row.scouts.total"
              :key="`table-column-scout-${scout.abreviacao}`"
              :label="scout.abreviacao"
              :field="scout.abreviacao"
              width="10"
              numeric
              :sortable="!isMobile"
            >
              {{ scout.valor }}
            </b-table-column>

            <b-table-column
              :visible="!isMobile"
              label="Confronto"
              width="100"
              numeric
            >
              <div class="level top match-aligment">
                <div class="level-left">
                  <img :src="props.row.confronto.mandante.escudo" width="20" />
                </div>
                <div class="level-item">
                  <b>X</b>
                </div>
                <div class="level-right">
                  <img :src="props.row.confronto.visitante.escudo" width="20" />
                </div>
              </div>
            </b-table-column>
          </template>

          <template slot="bottom-left">
            <b-field label="Atletas/Página">
              <b-select v-model="params.limit" @input="searchAthletes" expanded>
                <option :value="15">15</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
              </b-select>
            </b-field>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <p>Sem Registros.</p>
              </div>
            </section>
          </template>
        </b-table>

        <section v-else class="section">
          <div class="content centralize">
            <div loading></div>
          </div>
        </section>
      </div>
    </BasePage>
    <ModalNPSVue
      ref="nps"
      name="NPS Tela Atletas"
      text="Está gostando da tela de Atletas?"
    ></ModalNPSVue>
  </BaseLayout>
</template>

<script>
import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";
import Amplitude from "../../../../services/amplitude";

import FilterAthletes from "@/apps/Panel/components/Athletes/Filter.vue";
import PlayerColumnMobile from "@/apps/Panel/components/Athletes/PlayerColumn.vue";
import ModalNPSVue from "../../components/ModalNPS.vue";

import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";
import AthleteService from "@/apps/Panel/services/athleteService";
import CommonService from "@/apps/Panel/services/commonService";
import qs from "qs";
import { mapGetters } from "vuex";

const athleteService = new AthleteService();
const commonService = new CommonService();

export default {
  name: "Athletes",
  mixins: [championshipMixins],
  components: {
    BaseLayout,
    BasePage,
    FilterAthletes,
    PlayerColumnMobile,
    ModalNPSVue,
  },
  data() {
    return {
      isLoadingAthletes: false,
      isLoading: false,
      params: {
        limit: 15,
        page: 1,
        groupScouts: 1,
        orderColumn: "preco_atual",
        order: "desc",
      },
      filterParams: {},
      scoutsGroups: [],
      athletes: [],
      count: 0,
      matches: [],
      sortIcon: "arrow-down",
      athleteSelected: null,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    amplitudeInstance() {
      const { id, email, nome, plano, expirado, limitAccess } = this.userInfo;
      return new Amplitude({ id, email, nome, plano, expirado, limitAccess });
    },
    isAsc() {
      return this.params.order === "asc";
    },
    hideScoutsGeneral() {
      if (this.isBrazilChampionship) {
        return this.params.groupScouts === 1;
      } else {
        return false;
      }
    },
    sortableColumns() {
      if (this.params.groupScouts === 1) {
        return [
          { field: "preco_atual", label: "Preço" },
          { field: "valorizacao", label: "Valorização" },
          { field: "min_para_valorizar", label: "Mínimo Valorizar" },
          { field: "media_pnt_basica", label: "Média Básica" },
          { field: "media_pnt", label: "Média Total" },
        ];
      }
      if (this.athletes.length > 0) {
        return this.athletes[0].scouts.total.map((a) => ({
          field: a.abreviacao,
          label: a.abreviacao,
        }));
      }
      return [];
    },
  },
  async created() {
    await this.loadChampionships();

    if (this.isBrazilChampionship) {
      this.params["ds_status_atleta.in"] = "provável";
    } else {
      delete this.params["ds_status_atleta.in"];
      this.params["orderColumn"] = "media_pnt";
    }

    this.loadData();
  },
  methods: {
    handleAthleteDetails(athleteId) {
      if (this.isBrazilChampionship && Number(athleteId)) {
        this.$router.push({ path: `/painel/atletas/${athleteId}` });
      }
    },
    setDefaultParams() {
      this.params = {
        limit: 15,
        page: 1,
        groupScouts: 1,
        orderColumn: "preco_atual",
        order: "desc",
      };

      if (this.isBrazilChampionship) {
        this.params["ds_status_atleta.in"] = "provável";
      } else {
        this.params["orderColumn"] = "media_pnt";
      }
    },
    changePage(page) {
      this.params.page = page;
      this.searchAthletes();
    },
    loadData() {
      this.isLoading = true;
      Promise.all([
        this.searchAthletes(),
        this.getScoutsGroups(),
        this.loadMatches(),
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    loadMatches() {
      const round = this.getChampionship.rodadaAtual;
      if (isNaN(round)) return;

      commonService
        .getMatches(
          {
            region: this.getChampionship.regiao.id,
            championship: this.getChampionship.id,
          },
          round
        )
        .then((response) => {
          this.matches = response;
        });
    },
    searchAthletes() {
      this.isLoadingAthletes = true;

      const params = qs.stringify(this.params);

      if (!this.isBrazilChampionship) {
        delete params["ds_status_atleta.in"];
      }

      athleteService
        .searchAthletes(
          {
            region: this.getChampionship.regiao.id,
            championship: this.getChampionship.id,
          },
          params
        )
        .then((response) => {
          this.athletes = response.data;
          this.count = response.count;
        })
        .catch(() => {
          this.athletes = [];
          this.count = 0;

          this.$buefy.snackbar.open({
            message: "Não foi possível carregar os dados de atletas",
            type: "is-danger",
            position: "is-right",
            indefinite: true,
          });
        })
        .finally(() => {
          this.isLoadingAthletes = false;
        });
    },
    getScoutsGroups() {
      athleteService.getScoutsGroups().then((response) => {
        this.scoutsGroups = response;
      });
    },
    handleChangeChampionship() {
      this.setDefaultParams();
      this.loadData();
    },
    handleGroupScout() {
      this.searchAthletes();
    },
    changeSort() {
      this.params.order = this.isAsc ? "desc" : "asc";
      this.sortIcon = this.isAsc ? "arrow-up" : "arrow-down";

      this.searchAthletes();
    },
    handleSort(field, order) {
      const activeOrder = this.params.order;
      const activeOrderColumn = this.params.orderColumn;

      if (activeOrderColumn === field) {
        this.params.order = activeOrder === "desc" ? "asc" : "desc";
      } else {
        this.params.orderColumn = field;
        this.params.order = order;
      }

      this.searchAthletes();
    },
    getStatusPlayer(statusPlayer) {
      const staus = statusPlayer.toLowerCase();
      if (staus === "provável") return "/svg/provavel.svg";
      if (staus === "contundido") return "/svg/contundido.svg";
      if (staus === "suspenso") return "/svg/suspenso.svg";
      if (staus === "dúvida") return "/svg/duvida.svg";
    },
    hasIconStatusPlayer(player) {
      const status = ["provável", "contundido", "suspenso", "dúvida"];
      return status.includes(player.status.toLowerCase());
    },
    getTypeByValue(value) {
      return value < 0 ? "is-negative" : "is-positive";
    },
    formatMediaMando(athelete) {
      return athelete.mandoDeJogo === "casa"
        ? athelete.media.casa
        : athelete.media.fora;
    },
    handleSortColumn(column) {
      const activeSort = column.field === this.params.orderColumn;
      const activeOrderIsAsc = this.params.order === "asc";

      if (activeSort) {
        return activeOrderIsAsc ? "sort-up" : "sort-down";
      } else {
        return "sort";
      }
    },
    handleFilter(filters) {
      const referencesFilter = {};

      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0) referencesFilter[key] = value.join(",");
          else delete referencesFilter[key];
        } else if (value !== "") {
          referencesFilter[key] = value;
        }
      });
      const { limit, page, groupScouts, orderColumn, order } = this.params;
      this.params = {
        limit,
        page,
        groupScouts,
        orderColumn,
        order,
        ...referencesFilter,
      };
      this.searchAthletes();
    },
    handlerEveryClick() {
      const isValidated = localStorage.getItem("NPS Tela Atletas");
      if (!isValidated) {
        this.$refs.nps.handlerEveryClick();
      }
    },
  },
};
</script>

<style lang="scss">
#athletes {
  .table-container {
    border-radius: 4px;
    background-color: #34374b;
    padding: 0.75em;

    @media (max-width: 768px) {
      .pagination {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;

        .pagination-next,
        .pagination-previous {
          flex-grow: 0;
        }
      }
    }
  }

  .b-table {
    tbody {
      @media (max-width: 758px) {
        tr {
          td {
            width: 100%;
            display: flex;
            position: relative;
          }
        }
      }
    }
  }

  .match-aligment {
    max-width: 70px;
    margin-left: auto;
  }

  .media-value-content {
    display: flex;
    flex-direction: column;

    small {
      font-size: 0.8em;
      opacity: 0.2;
    }
  }

  .player-status {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }

  .grid-player {
    height: 50px;
    overflow: hidden;
    display: flex;
    position: relative;
    padding-left: 25px;

    .player-info {
      margin-left: 20px;
      p {
        font-weight: 500;
        line-height: 1em;
      }
      .player-subtitle {
        font-size: 0.7em;
        font-weight: normal;
      }
    }

    .player-image {
      height: 45px;
      background-color: white;
      border-radius: 4px;
      overflow: hidden;
      min-width: 45px;

      img {
        height: 45px;
      }
    }

    .player-team-shield {
      position: absolute;
      top: 3px;
      left: 0px;
      width: 20px;

      img {
        width: 20px;
      }
    }
  }

  div[loading] {
    border: 3px solid hsla(185, 100%, 62%, 0.2);
    border-top-color: #3cefff;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .options-groups-scouts {
    @media (min-width: 769px) {
      margin-right: 1em;

      label {
        font-size: 0.9em !important;
      }
    }

    @media (max-width: 768px) {
      margin-bottom: 0.5em;

      label {
        font-size: 0.8em !important;
      }

      .control {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
      }
    }

    .b-radio.radio.button {
      background-color: #7285a5;
      border-color: #7285a5;
      color: white;

      &:hover {
        background-color: #34374b;
        border-color: #34374b;
        color: white !important;
      }
    }

    .b-radio.radio.button.is-independence {
      background-color: #4c516d;
      border-color: #4c516d;
    }
  }
}
</style>
