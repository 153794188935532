<template>
  <div class="nps-position-info">
    <b-notification
      :active="isAway"
      :indefinite="true"
      container="body"
      position="is-top"
      type="is-pigeon"
      @close="closeNotifyAway"
    >
      <div class="nps-content">
        <p>Fala Cartoleiro!<br />{{ text }}</p>
        <div class="nps-buttons">
          <b-tooltip type="is-bluedark2" label="Gostei">
            <button @click="($event) => handleEventClick('like', $event)">
              <b-icon pack="fa" icon="thumbs-up"></b-icon>
            </button>
          </b-tooltip>
          <b-tooltip type="is-bluedark2" label="Não Gostei">
            <button @click="($event) => handleEventClick('dislike', $event)">
              <b-icon pack="fa" icon="thumbs-down"></b-icon>
            </button>
          </b-tooltip>
        </div>
      </div>
    </b-notification>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Amplitude from "../../../services/amplitude";
import watchAway from "../mixins/watchAway";

export default {
  name: "ModalNPS",
  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  mixins: [watchAway],
  computed: {
    ...mapGetters(["userInfo"]),
    amplitudeInstance() {
      const { id, email, nome, plano, expirado, limitAccess } = this.userInfo;
      return new Amplitude({ id, email, nome, plano, expirado, limitAccess });
    },
  },
  methods: {
    handleEventClick(type, event) {
      event.preventDefault();
      event.stopPropagation();

      this.amplitudeInstance.createEvent(this.name, {
        descricao: type,
        valor: type === "like" ? 1 : 0,
      });

      this.setValidate();
      this.closeNotifyAway();
    },
    setValidate() {
      localStorage.setItem(this.name, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.nps-position-info {
  z-index: 35;
  position: fixed;
  bottom: 4em;
  right: 2em;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);

  .nps-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 16px !important;
      margin-bottom: 1.5em;
      text-align: center;
    }
  }

  .nps-buttons {
    height: 3em;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: white;
    }
    .icon {
      cursor: pointer;
      margin: 0px 1em;
      font-size: 2.5em;
    }
  }
}
</style>
