<template>
  <div class="filter-content has-background-bluedark3">
    <div class="columns is-multiline">
      <!-- Gols ou Assistencia -->
      <div class="column is-half">
        <b-field label="">
          <b-select expanded v-model="param.scout">
            <option value="Gol">Gol</option>
            <option value="Assistência">Assistência</option>
          </b-select>
        </b-field>
      </div>

      <!-- Jogos -->
      <div class="column is-half">
        <b-field label="">
          <b-select expanded v-model="param.jogos">
            <option value="todos">Todos</option>
            <option value="ult_3">Últimos 3 jogos</option>
            <option value="ult_5">Últimos 5 jogos</option>
            <option value="1_turno">1 Turno</option>
            <option v-if="mercado.rodada_atual > 19" value="2_turno"
              >2 Turno</option
            >
          </b-select>
        </b-field>
      </div>

      <!-- Conquistado | Cedido -->
      <div class="column is-full">
        <div class="columns">
          <div class="column centralize">
            <div class="filter-emblems">
              <div class="view-icon has-background-bluedark3">
                <b-icon pack="fas" icon="eye" size="is-small"></b-icon>
              </div>

              <div
                @click="troggleView('conquistado')"
                :class="{ 'emblem-select': fin_view.conquistado }"
                class="emblem centralize"
                :style="{
                  marginRight: '1em',
                  cursor: !viewFixture ? 'not-allowed' : 'pointer',
                }"
              >
                <img
                  v-if="viewFixture"
                  :src="
                    param.origem == 'conquistado_cedido'
                      ? partida_selecionada.clubes.casa.escudo
                      : partida_selecionada.clubes.fora.escudo
                  "
                  :alt="
                    param.origem == 'conquistado_cedido'
                      ? partida_selecionada.clubes.casa.sigla
                      : partida_selecionada.clubes.fora.sigla
                  "
                  width="35"
                />
                <span v-else>CON</span>
              </div>
              <div
                @click="troggleView('cedido')"
                :class="{ 'emblem-select': fin_view.cedido }"
                class="emblem centralize"
                :style="{ cursor: !viewFixture ? 'not-allowed' : 'pointer' }"
              >
                <img
                  v-if="viewFixture"
                  :src="
                    param.origem == 'cedido_conquistado'
                      ? partida_selecionada.clubes.casa.escudo
                      : partida_selecionada.clubes.fora.escudo
                  "
                  :alt="
                    param.origem == 'cedido_conquistado'
                      ? partida_selecionada.clubes.casa.sigla
                      : partida_selecionada.clubes.fora.escudo
                  "
                  width="35"
                />
                <span v-else>CED</span>
              </div>
            </div>
          </div>
          <div class="column" :class="{ centralize: $mq == 'sm' }">
            <div :style="{ marginTop: '1em' }">
              <div v-if="!viewFixture">
                <b-radio
                  v-model="param.origem"
                  name="name"
                  native-value="conquistado"
                  @input="changeOrigemConf()"
                  :size="$mq === 'sm' ? 'is-medium' : ''"
                >
                  Conquistado
                </b-radio>
              </div>
              <div v-if="!viewFixture">
                <b-radio
                  v-model="param.origem"
                  name="name"
                  native-value="cedido"
                  @input="changeOrigemConf()"
                  :size="$mq === 'sm' ? 'is-medium' : ''"
                >
                  Cedido
                </b-radio>
              </div>
              <div v-if="viewFixture">
                <b-radio
                  v-model="param.origem"
                  name="name"
                  native-value="conquistado_cedido"
                  @input="changeOrigemConf()"
                  :size="$mq === 'sm' ? 'is-medium' : ''"
                >
                  <!-- <span class="ga-con-lg">Conquistado do {{ partida_selecionada.clubes.casa.sigla }}</span> -->
                  <span class="ga-con-md"
                    >CON {{ partida_selecionada.clubes.casa.sigla }}</span
                  >

                  <span
                    class="ga-x"
                    :style="{ margin: '0 .5em', opacity: '0.4' }"
                    >x</span
                  >

                  <!-- <span class="ga-con-lg">Cedido do {{ partida_selecionada.clubes.fora.sigla }}</span> -->
                  <span class="ga-con-md"
                    >CED {{ partida_selecionada.clubes.fora.sigla }}</span
                  >
                </b-radio>
              </div>
              <div v-if="viewFixture">
                <b-radio
                  v-model="param.origem"
                  name="name"
                  native-value="cedido_conquistado"
                  @input="changeOrigemConf()"
                  :size="$mq === 'sm' ? 'is-medium' : ''"
                >
                  <!-- <span class="ga-con-lg">Cedido do {{ partida_selecionada.clubes.casa.sigla }}</span> -->
                  <span class="ga-con-md"
                    >CED {{ partida_selecionada.clubes.casa.sigla }}</span
                  >

                  <span
                    class="ga-x"
                    :style="{ margin: '0 .5em', opacity: '0.4' }"
                    >x</span
                  >

                  <!-- <span class="ga-con-lg">Conquistado do {{ partida_selecionada.clubes.fora.sigla }}</span> -->
                  <span class="ga-con-md"
                    >CON {{ partida_selecionada.clubes.fora.sigla }}</span
                  >
                </b-radio>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Posicao -->
      <div class="column is-full">
        <v-select
          class="form-select multiple-select"
          multiple
          placeholder="Posições"
          :options="filteredPositions"
          v-model="param.posicao"
        ></v-select>
      </div>

      <!-- Bola -->
      <div class="column is-half">
        <v-select
          class="form-select multiple-select"
          multiple
          placeholder="Jogada"
          :options="filteredBola"
          v-model="param.bola"
        ></v-select>
      </div>

      <!-- Tipo jogada -->
      <div class="column is-half">
        <v-select
          :disabled="param.bola.length == 0"
          class="form-select multiple-select"
          multiple
          placeholder="Tipo"
          :options="filteredTipoJogada"
          v-model="param.tipo_jogada"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import FinalizacoesMixin from "@/modules/requests/Finalizacoes";

export default {
  name: "Filters",
  components: {
    "v-select": vSelect,
  },
  mixins: [FinalizacoesMixin],
  props: {
    partida: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clube_conquistado: {},
      clube_cedido: {},
      param: {
        bola: [],
        tipo_jogada: [],
        posicao: [],
        scout: "Gol",
        origem: "conquistado",
        jogos: "todos",
      },
    };
  },
  watch: {
    partida_selecionada: {
      handler: function(n, o) {
        if (n) {
          this.param.origem = "conquistado_cedido";
          this.clube_conquistado = n.clubes.casa;
          this.clube_cedido = n.clubes.fora;
        } else {
          this.param.origem = "conquistado";
          this.clube_conquistado = {};
          this.clube_cedido = {};
        }
      },
      deep: true,
    },
    param: {
      handler: function(n, o) {
        if (n) {
          // atualizando os parametros
          this.setFinParams(n);
          this.getFinalizacoes();
        }
      },
      deep: true,
    },
    "param.origem": {
      handler: function(n, o) {
        if (!this.viewFixture && n != o) {
          let aux = n == "conquistado" ? "cedido" : "conquistado";
          this.finTroggleView(n);
          this.finTroggleView(aux);
        }
      },
    },
    "param.scout": {
      handler: function(n, o) {
        if (n) {
          // quando alterar o scout ira resetar o restante das infos,
          // para nao pesquisar infos de scout errado

          this.param.tipo_jogada = [];
          this.setFinParams(this.param);
        }
      },
      deep: true,
    },
    "param.bola": {
      handler: function(n, o) {
        if (n) {
          this.param.tipo_jogada = [];
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getTipoJogadas();

    this.param = { ...this.fin_params };
  },
  computed: {
    ...mapGetters(["posicoes", "mercado"]),
    ...mapGetters(["partida_selecionada", "fin_params", "fin_view"]),
    viewFixture() {
      return this.partida_selecionada ? true : false ? true : false;
    },
    filteredPositions() {
      let aux = this.posicoes.map((e) => e.sigla.toUpperCase());
      return aux;
    },
    filteredBola() {
      if (!this.loadingTipoJogadas)
        return Object.keys(this.TipoJogadas[this.param.scout]);
      else return [];
    },
    filteredTipoJogada() {
      let res = [];
      this.param.bola.map((e) => {
        res.push(...this.TipoJogadas[this.param.scout][e]);
      });
      return res.filter((e, i) => res.indexOf(e) == i);
    },
  },
  methods: {
    ...mapActions(["changePartida", "setFinParams", "finTroggleView"]),
    changeOrigemConf() {},
    troggleView(ev) {
      if (this.viewFixture) this.finTroggleView(ev);
    },
  },
};
</script>

<style scoped>
/* @media (min-width: 1800px) {
  .ga-con-lg {
    display: inline-block;
  }
  .ga-con-md {
    display: none;
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .ga-con-lg {
    display: none;
  }
  .ga-con-md {
    display: inline-flex;
    font-size: 0.9em;
  }
  .ga-x {
    margin: 0.5em !important
  }
} */
.ga-con-md {
  display: inline-flex;
  font-size: 0.9em;
}

.filter-content {
  padding: 1em;
  border-radius: 4px;
}
.filter-emblems {
  position: relative;
  padding: 12px;
  display: flex;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23999999FF' stroke-width='4' stroke-dasharray='14' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  flex-direction: row;
}
.view-icon {
  position: absolute;
  top: -15px;
  left: 50%;
  color: rgb(158, 154, 154);
  transform: translate(-50%, 0%);
  padding: 0.5em 0.75em 0.2em 0.75em;
}
.emblem {
  z-index: 10;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #4f4f4f;
  opacity: 0.3;
  padding: 0.5em;
  height: 60px;
  width: 60px;
}
.emblem:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  border: 1px solid rgba(0, 255, 204, 1);
}
.emblem-select {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  background-color: rgba(0, 255, 204, 0.75);
  color: black;
  -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  border: 1px solid rgba(0, 255, 204, 1);
}
</style>
