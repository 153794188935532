<template>
  <div id="modal-analysis-details" class="modal-card">
    <div class="card">
      <b-loading
        :active.sync="loadingDetails"
        :is-full-page="false"
      ></b-loading>
      <div class="card-header-2">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="info-alignment">
              <h2 class="is-size-5">{{ title }}</h2>
              <div class="is-flex">
                <p class="card-subtitle">{{ subtitle }}</p>
                <span class="marker">•</span>
                <p class="card-subtitle">{{ labelMatchesFilter }}</p>
                <span class="marker">•</span>
                <p class="card-subtitle">{{ clubSelected.nome }}</p>
              </div>
            </div>
          </div>
          <div class="level-right">
            <b-button
              type="is-magenta"
              icon-right="close"
              size="is-small"
              @click="$emit('close')"
            ></b-button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="actual-match">
          <div class="level is-mobile">
            <div class="level-left">
              <img :src="actualMatch.data.mandante.escudo" />
            </div>
            <div
              class="level-item"
              :class="{ 'centralize-row': !isMobile, centralize: isMobile }"
            >
              <span :selected="isTeamSelected(actualMatch.data.mandante.id)">{{
                actualMatch.data.mandante.nome
              }}</span>
              <b>X</b>
              <span :selected="isTeamSelected(actualMatch.data.visitante.id)">{{
                actualMatch.data.visitante.nome
              }}</span>
            </div>
            <div class="level-right">
              <img :src="actualMatch.data.visitante.escudo" />
            </div>
          </div>
          <p>Partida Atual</p>
        </div>

        <b-collapse
          v-for="(match, index) of matches"
          :key="match.partidaId"
          class="card-match-analysis-details"
          animation="slide"
          :open="isOpenCard == index"
          @open="isOpenCard = index"
          @close="isOpenCard = null"
        >
          <template slot="trigger">
            <div class="card-match-header" role="button">
              <div
                class="columns is-multiline is-gapless"
                :class="{ 'd-flex': isMobile }"
              >
                <div class="column is-narrow is-full-mobile">
                  <div class="grid-match">
                    <div class="level is-mobile">
                      <div class="level-left">
                        <img :src="getHomeTeamByState(match).escudo" />
                      </div>
                      <div class="level-item centralize-row">
                        <span>{{ getHomeTeamByState(match).abreviacao }}</span>
                        <b>{{
                          `${match.placarMandante} X ${match.placarVisitante}`
                        }}</b>
                        <span>{{ getAwayTeamByState(match).abreviacao }}</span>
                      </div>
                      <div class="level-right">
                        <img :src="getAwayTeamByState(match).escudo" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-half-mobile">
                  <div v-if="match.atletas.length > 0" class="group-athletes">
                    <div class="athletes-group">
                      <div
                        class="athletes-group-item"
                        v-for="athlete of match.atletas.filter((a, i) => i < 3)"
                        :key="athlete.id"
                      >
                        <img :src="athlete.foto" />
                      </div>
                      <div
                        v-if="match.atletas.length > 3"
                        class="athletes-group-item default-more-item"
                      >
                        +{{ match.atletas.length - 3 }}
                      </div>
                    </div>
                    <span>Ver Lista de Jogadores</span>
                  </div>
                </div>
                <div class="column is-narrow is-half-mobile">
                  <p class="match-round">
                    Rodada <b>{{ match.rodada }}</b>
                  </p>
                  <div class="label-group" align-right>
                    <label>{{ params.apuracao }}</label>
                    <b-tag
                      :type="
                        match[params.apuracao] == 0
                          ? 'is-pigeon'
                          : match[params.apuracao] > 0
                          ? 'is-positive'
                          : 'is-negative'
                      "
                    >
                      <p>{{ match[params.apuracao] }}</p>
                    </b-tag>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="card-content-detail">
            <div v-if="match.atletas.length === 0">
              <div class="content">
                Não há registros para esta partida.
              </div>
            </div>
            <b-table v-else :data="match.atletas" narrowed>
              <template slot-scope="props">
                <b-table-column label="Atleta">
                  <div class="detail-align-athlete">
                    <img
                      :src="props.row.partida[props.row.mandoDeJogo].escudo"
                    />
                    <img class="athlete-photo" :src="props.row.foto" />
                    <div>
                      <p>{{ props.row.nome }}</p>
                      <span>{{ props.row.posicao.descricao }}</span>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column width="10" label="Pnt." numeric>
                  {{ props.row.pontuacao.toFixed(2) }}
                </b-table-column>
                <b-table-column
                  :width="params.tipo === 'scout' ? 50 : 250"
                  :label="
                    params.tipo === 'scout' ? params.scout.cd_scout : 'Scouts'
                  "
                >
                  <div class="columns is-multiline is-mobile is-gapless">
                    <div
                      v-for="scout of props.row.scouts"
                      :key="`scout-${scout.abreviacao}`"
                      class="column is-narrow align-scouts"
                      v-show="isVisibleScout(scout)"
                    >
                      <span>{{ scout.valor }}</span>
                      <span>{{ scout.abreviacao }}</span>
                    </div>
                  </div>
                </b-table-column>
              </template>
            </b-table>
          </div>
        </b-collapse>
        <b-pagination
          v-if="showPagination"
          :current.sync="page"
          :total="total"
          type="is-pigeon"
          :per-page="limit"
          aria-next-label="Próxima Página"
          aria-previous-label="Página Anterior"
          aria-page-label="Página"
          aria-current-label="Página atual"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";
import ScoutsAnalysisService from "../../services/scoutsAnalysisService";

const scoutsAnalysisService = new ScoutsAnalysisService();

export default {
  name: "ModalDetails",
  mixins: [championshipMixins],
  props: {
    params: {
      type: Object,
      required: true,
    },
    actualMatch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingDetails: false,
      isOpenCard: null,
      analysisDetails: null,
      page: 1,
      limit: 5,
      filters: [
        { slug: "todos", label: "todos" },
        { slug: "ult_3", label: "últimos 3 jogos" },
        { slug: "ult_5", label: "últimos 5 jogos" },
        { slug: "fase_1", label: "1 turno" },
        { slug: "fase_2", label: "2 turno" },
      ],
    };
  },
  computed: {
    total() {
      return this.analysisDetails ? this.analysisDetails.count : 0;
    },
    showPagination() {
      return this.analysisDetails !== null && this.analysisDetails.count > 5;
    },
    labelMatchesFilter() {
      const { label } = this.filters.find((f) => f.slug === this.params.jogos);
      return label ? label : "";
    },
    title() {
      return this.params.scout
        ? `Análise de ${this.params.scout.ds_scout} ${this.params.apuracao}`
        : `Média de pontuação ${this.params.apuracao}`;
    },
    subtitle() {
      return this.params.mandoDeJogo === "ambos"
        ? "Todos os mandos"
        : "De acordo com o mando atual";
    },
    clubSelected() {
      const { data, choose } = this.actualMatch;
      return data[choose];
    },
    matches() {
      if (!this.analysisDetails) return [];
      const { data } = this.analysisDetails;
      return data ? data : [];
    },
  },
  watch: {
    page() {
      this.fetchAnalysisDetails();
    },
  },
  created() {
    this.fetchAnalysisDetails();
  },
  methods: {
    fetchAnalysisDetails() {
      this.loadingDetails = true;

      const position = this.getPosition();
      const scout = this.getScout();
      const queryParams = qs.stringify({
        tipo: this.params.tipo,
        posicao: position,
        scout,
        mandoDeJogo: this.params.mandoDeJogo,
        jogos: this.params.jogos,
        apuracao: this.params.apuracao,
        clube: this.params.clube,
        clubeMandoDeJogo: this.params.clubeMandoDeJogo,
        limit: this.limit,
        page: this.page,
      });

      scoutsAnalysisService
        .getAnalysisDetails(
          {
            region: this.getChampionship.regiao.id,
            championship: this.getChampionship.id,
          },
          queryParams
        )
        .then((analysisDetails) => {
          const { data, count } = analysisDetails;
          this.analysisDetails = { count, data };
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    },
    sortAthletesByPontuation(athletes) {
      return athletes.sort((a, b) => b.pontuacao - a.pontuacao);
    },
    isVisibleScout(scout) {
      return this.params.tipo === "pontuacao" ? scout.valor != 0 : true;
    },
    isTeamSelected(id) {
      return this.clubSelected.id === id;
    },
    getHomeTeamByState(match) {
      const { clube, adversario, clubeMandoDeJogo } = match;

      return clubeMandoDeJogo === "mandante" ? { ...clube } : { ...adversario };
    },
    getAwayTeamByState(match) {
      const { clube, adversario, clubeMandoDeJogo } = match;

      return clubeMandoDeJogo === "mandante" ? { ...adversario } : { ...clube };
    },
    getScout() {
      return this.params.scout && typeof this.params.scout === "object"
        ? this.params.scout.cd_scout
        : this.params.scout;
    },
    getPosition() {
      return typeof this.params.posicao === "object"
        ? this.params.posicao.abreviacao
        : this.params.posicao;
    },
  },
};
</script>

<style lang="scss">
.modal-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 768px) {
    width: 600px;
  }

  @media (max-width: 768px) {
    .card {
      height: 100vh !important;
      overflow-y: auto;
    }
  }

  .align-buttons {
    width: 100%;
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }

  .actual-match {
    width: auto;
    margin: 1em 0;
    border-radius: 4px;
    padding: 0.5em;
    background-color: $blue-dark-3;

    @media (max-width: 768px) {
      margin: 1em auto 1em auto;

      span {
        font-size: 0.8em;
      }

      img {
        width: 30px !important;
      }
    }

    .level {
      margin-bottom: 0px;
    }

    p {
      font-size: 0.7em;
      opacity: 0.5;
      margin-top: 0em;
      text-align: center;
    }

    span {
      margin: 0 0.5em;
      font-weight: bold;

      &[selected] {
        color: $primary;
      }

      b {
        margin: 0 0.25em;
      }
    }

    img {
      width: 35px;
    }
  }

  .card-body {
    padding: 1em;
    max-height: 65vh;
    overflow-y: auto;
  }

  .card-header-2 {
    padding: 1em 1em 0.5em 1em;

    .info-alignment {
      font-size: 0.9em;
    }

    .marker {
      margin: 0 0.5em;
    }
    @media (max-width: 768px) {
      .marker {
        display: none;
      }
      .info-alignment {
        font-size: 0.8em;
        .is-flex {
          flex-direction: column;
        }
      }
    }
  }

  .card-content-detail {
    padding: 0.5em 0.25em;
    font-size: 0.9em;

    .align-scouts {
      padding: 0.15em !important;

      span {
        font-size: 0.7em;
      }
      span:first-child {
        margin: 0.25em;
      }
    }

    .b-table {
      .table {
        background: transparent !important;
        border: 4px;
        overflow: hidden;

        tbody tr {
          @media (max-width: 758px) {
            & {
              border-radius: 7px !important;
              border: none !important;
              overflow: hidden;
              background-color: $blue-dark-3;
              margin-bottom: 0.5em !important;
              padding: 0px !important;

              td {
                border: none !important;
                padding: 0.5em 0.5em !important;
                background-color: $blue-dark-3 !important;

                &::before {
                  padding-right: 0 !important;
                }

                .checkbox {
                  margin: 0 !important;
                }
              }
            }
          }
        }
        tbody tr {
          padding: 0.75em;

          td {
            border: none !important;
          }
        }
        thead {
          tr {
            th {
              background: $blue-dark-3 !important;
              border: none !important;
              &:first-child {
                border-radius: 4px 0 0 4px;
              }
              @media (max-width: 1400px) {
                p {
                  font-size: 0.8em !important;
                }
              }
            }
          }
        }
        @media (min-width: 758px) {
          tbody tr td {
            background-color: $blue-dark-2 !important;
          }
          tbody tr:nth-child(2n) {
            td {
              background-color: $blue-dark-5 !important;
            }
          }
        }
      }
    }
  }

  .card-match-analysis-details {
    margin-bottom: 0.5em;

    .match-round {
      font-size: 0.7em;
      line-height: 1em;

      b {
        font-size: 1.2em !important;
      }
    }

    .detail-align-athlete {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        margin-right: 0.5em;
      }

      .athlete-photo {
        width: 30px;
        background-color: white;
        border-radius: 4px;
      }

      div {
        display: flex;
        flex-direction: column;

        p {
          font-size: 0.8em;
        }
        span {
          font-size: 0.7em;
        }
      }
    }

    .card-match-header {
      border-radius: 4px;
      padding: 0.75em;
      background-color: $blue-dark-2;

      .label-group {
        margin-top: 0.25em;
        display: flex;
        flex-direction: column;

        .tag {
          margin: 0 0 0 auto;
          min-width: 30px;
          max-width: 40px;
        }

        &[align-right] {
          text-align: right;
        }

        &[numeric] {
          p {
            font-size: 0.8em !important;
          }
        }

        label {
          font-size: 0.7em;
          opacity: 0.8;
          text-transform: capitalize;
        }
        p {
          font-size: 1.21em;
          font-weight: bold;
        }
      }

      .group-athletes {
        padding-left: 1em;

        span {
          margin-left: 0.5em;
          font-size: 0.7em;
        }
      }

      .athletes-group {
        display: flex;
        padding-left: 0.5em;
        padding-top: 0.5em;
        align-items: center;

        .default-more-item {
          background-color: $pigeon !important;
          letter-spacing: -0.1em;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 0.2em;
        }
        .athletes-group-item {
          margin-left: -0.5em;
          border-radius: 50%;
          border: 1px solid $blue-dark-2;
          height: 25px;
          width: 25px;
          overflow: hidden;
          background-color: white;
          font-size: 0.8em;
          text-align: center;
          font-weight: bold;

          img {
            height: 25px;
          }
        }
      }
      .d-flex {
        display: flex;

        .is-half-mobile {
          margin-top: 0.5em;

          &:last-child {
            text-align: right;
          }
        }
      }

      .grid-match {
        border-radius: 4px;
        padding: 0.5em;
        background-color: $blue-dark-1;

        .level.is-mobile .level-item:not(:last-child) {
          margin-right: 0;
        }

        .level-left,
        .level-right {
          width: 25px;
        }
        .level-item {
          width: 130px;

          span {
            font-size: 0.9em;
            margin: 0 0.5em;
          }
        }

        img {
          width: 25px;
        }
      }
    }
  }
}
</style>
