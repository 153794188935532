const PHONE_SUPPORT_DEFAULT = '5521974399122';

const handleDurationMessage = (message) => {
  const countChars = message.length;
  const msReadChar = 80;
  return countChars * msReadChar;
};

export default {
  methods: {
    notify(message, type = 'is-primary', position = 'is-top') {
      const duration = handleDurationMessage(message);

      const params = {
        duration,
        type,
        message,
        position,
        actionText: 'Ok',
      };

      try {
        this.$buefy.snackbar.open(params);
      } catch {}
    },
    callSupport(text = '') {
      if (text) {
        text = encodeURI(text);
      }

      window.open(
        `https://api.whatsapp.com/send?phone=${PHONE_SUPPORT_DEFAULT}&text=${text}`,
        '_blank'
      );
    },
  },
};
