<template>
  <div class="">
    <div class="block-select-rodada has-background-bluedark3">
      <div class="level is-mobile">
        <div class="level-item">
          <div class="centralize">
            <label>{{ rodada }}ª Rodada</label>
            <small>Clique em um time abaixo para visualizar o confronto</small>
          </div>
        </div>
        <div v-if="$mq == 'sm'" class="level-right">
          <b-button
            @click="handlerView()"
            icon-left="chevron-down"
            icon-pack="fas"
            type="is-light"
            outlined
          ></b-button>
        </div>
      </div>
    </div>
    <div v-if="showItemsMb || $mq != 'sm'" class="block-content-partidas">
      <div v-if="partidaIndex != null">
        <b-button
          type="is-danger"
          :style="{
            borderRadius: '0',
            color: 'rgb(106, 18, 18)',
            fontWeight: 'bold',
            fontSize: '1em',
          }"
          expanded
          @click="cleanSelect()"
        >
          Limpar Seleção
        </b-button>
      </div>

      <!-- Partidas -->
      <div class="bk-partida" v-if="!loading">
        <div v-if="Partidas.length > 0">
          <div
            class="bk-partida-row"
            :class="{ 'bk-partida-select': partidaIndex == i }"
            @click="selectRodada(partida, i)"
            v-for="(partida, i) of Partidas"
            :key="'partida-' + partida.partida.id"
          >
            <div
              v-if="partidaIndex == i"
              v-tooltip.left="{
                content: 'Detalhes da partida',
                classes: 'home-tooltip',
              }"
              class="bk-partida-button centralize-row"
            >
              <b-button
                tag="router-link"
                to="/painel/jogos"
                class="icon-button"
              >
                <b-icon pack="fas" icon="share" size="is-small"></b-icon>
              </b-button>
            </div>

            <div class="infos-partida">
              <span
                ><b>{{
                  _moment(partida.partida.data).format("ddd DD/MM/YYYY")
                }}</b></span
              >
              <span style="opacity: 0.7; margin: 0 .5em">{{
                partida.partida.local
              }}</span>
              <span
                ><b>{{
                  _moment(partida.partida.data).format("HH:mm")
                }}</b></span
              >
            </div>

            <div class="bk-align-partida">
              <div class="level is-mobile">
                <div class="level-item">
                  <span class="sigla-clube">
                    {{ partida.clubes.casa.sigla }}
                  </span>
                  <img :src="partida.clubes.casa.escudo" width="25" />
                </div>
                <div class="level-item">
                  <!-- <span class="placar-item">{{ partida.placar.casa }}</span>  -->
                  <span :style="{ margin: '0 .75em', opacity: '0.2' }">x</span>
                  <!-- <span class="placar-item">{{ partida.placar.fora }}</span>  -->
                </div>
                <div class="level-item">
                  <img :src="partida.clubes.fora.escudo" width="25" />
                  <span class="sigla-clube">
                    {{ partida.clubes.fora.sigla }}
                  </span>
                </div>
              </div>
            </div>

            <!-- <div class="infos-partida-2" :style="{ opacity: '0.3' }">
              <span v-if="_moment(partida.partida.data).isAfter(_moment())">PLACAR MAIS PROVÁVEL</span>
              <span v-else-if="_moment(partida.partida.data).isBefore(_moment()) && _moment(partida.partida.data).isAfter(_moment().add(3, 'h'))">EM ANDAMENTO</span>
            </div> -->

            <!-- <div class="level-item">
              <div v-for="(ap, iap) of partida.clubes.casa.aproveitamento" :key="'approve-'+iap">
                <div v-if="iap < 3" :class="`ball-aprov app-${ap}`"></div>
              </div>
            </div> -->
            <!-- <div class="level-item">
              <div v-for="(ap, iap) of partida.clubes.fora.aproveitamento" :key="'approve-'+iap">
                <div v-if="iap < 3" :class="`ball-aprov app-${ap}`"></div>
              </div>
            </div> -->
          </div>
        </div>

        <div v-else>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>Nenhum Registro.</p>
            </div>
          </section>
        </div>
      </div>

      <!-- Loader -->
      <div v-else class="section centralize">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import PartidasMixin from "@/modules/requests/Partidas";
import FinalizacoesMixin from "@/modules/requests/Finalizacoes";

export default {
  name: "Rodadas",
  mixins: [PartidasMixin, FinalizacoesMixin],
  data() {
    return {
      selectId: null,
      rodada: 1,
      showItemsMb: false,
    };
  },
  async created() {
    moment.locale("pt-br");
    await this.getMercado();
    await this.getAtualRodada();

    this.Partidas = this.Partidas.map((e) => {
      e.view = true;
      return e;
    });

    this.rodada = this.mercado.rodada_atual;
  },
  computed: {
    ...mapGetters(["mercado", "partida_selecionada", "partidaIndex"]),
  },
  methods: {
    ...mapActions(["getMercado"]),
    ...mapActions(["changePartida", "finTroggleViewFix", "setIndexPartida"]),
    _moment(a) {
      return moment(a);
    },
    handlerView() {
      this.showItemsMb = !this.showItemsMb;
    },
    async selectRodada(partida, index) {
      this.selectId = index;
      this.finTroggleViewFix({
        conq: true,
        ced: true,
      });
      await this.changePartida(partida);
      this.setIndexPartida(index);
      // ira carregar apenas se ja tiver uma partida selecionada
      // evitando carregar 2x, pois o filtro tbm ira fazer um carregamento
      if (this.selectId != null) this.getFinalizacoes();

      if (this.$mq == "sm") {
        this.showItemsMb = false;
      }
    },
    cleanSelect() {
      this.changePartida(null);
      this.finTroggleViewFix({
        conq: false,
        ced: true,
      });
      this.selectId = null;
      this.setIndexPartida(null);
    },
    // async increment() {
    //   this.rodada += 1;
    //   this.partidas = await this.getPartidas(this.rodada)
    // },
    // async decrement() {
    //   if (this.rodada > 1) this.rodada -= 1;
    //   this.partidas = await this.getPartidas(this.rodada)
    // },
    // async getPartidas (id) {
    //   let a = []
    //   try {
    //     this.loading = true
    //     a = (await this.$http.get(url + '/jogos/partidas/' + id)).data.data

    //   } catch (err) {
    //     console.log(err)
    //     this.loading = false
    //   }
    //   this.loading = false
    //   return a
    // },
  },
};
</script>

<style scoped>
.block-select-rodada {
  padding: 1.5em 1em;
  border-bottom: 1px solid rgba(24, 24, 24, 0.39);
  height: 65px;
}
.block-select-rodada label {
  font-weight: bold;
  text-transform: uppercase;
}
.block-select-rodada small {
  display: block;
  opacity: 0.3;
  font-size: 0.8em;
  font-weight: 300;
}
.placar-item {
  font-weight: bold;
  font-size: 1.4em;
}
.sigla-clube {
  text-align: right;
  font-weight: 100;
  margin: 0 0.75em;
}
.infos-partida {
  text-align: center;
  margin-bottom: 0.5em;
  font-size: 0.8em;
}
.infos-partida-2 {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.8em;
}
@media (max-width: 600px) {
  .bk-align-partida {
    width: 70% !important;
  }
}
.bk-align-partida {
  width: 80%;
  margin: 0 auto;
}
.bk-partida-row {
  position: relative;
  padding: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  border: 1px solid transparent;
  border-bottom-color: rgba(255, 255, 255, 0.05);
}
.icon-button {
  height: 100% !important;
  width: 100%;
  font-size: 1em;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-color: none;
}
.bk-partida-button {
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.131) !important;
}
.bk-partida-row:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  border: 1px solid rgba(0, 255, 204, 1);
}
.bk-partida-select {
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 255, 204, 0.75);
  color: black;
  -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 7px -1px rgba(0, 255, 204, 0.75);
  border: 1px solid rgba(0, 255, 204, 1);
}
.conteudo {
  padding: 1em;
}
.ball-aprov {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin: 0.2em;
  background-color: grey;
}
.app-v {
  background-color: green;
}
.app-d {
  background-color: red;
}
.app-e {
  background-color: grey;
}
</style>
