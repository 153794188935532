<template>
  <BaseLayout :championships="championships" is-page-for-default-championship>
    <BasePage id="goalsAndAssists" title="Gols e Assistência">
      <div class="ga-content-section">
        <div class="columns is-multiline">
          <!-- Exibindo partidas por cima apenas se for mobile -->
          <div v-if="$mq == 'sm'" class="column">
            <div class="ga-container has-background-bluedark2">
              <Fixtures
                @getPartida="handlerPartida($event)"
                @clean="cleanPartida()"
              />
            </div>
          </div>

          <div class="column is-four-fifths ga-align-column">
            <div class="ga-container has-background-bluedark2">
              <div
                class="ga-content has-background-bluedark3"
                :style="{ height: '65px', borderRadius: '4px 4px 0 0' }"
              >
                <div class="ga-hd-items">
                  <h2>Campo Virtual</h2>
                  <h2
                    v-if="$mq != 'sm'"
                    :style="{ marginRight: '.25em' }"
                    class="ga-h2-filtros"
                  >
                    Filtros
                  </h2>
                </div>
              </div>
              <div class="ga-divider"></div>
              <div
                class="ga-content has-background-bluedark2"
                :style="{ borderRadius: '0 0 4px 4px' }"
              >
                <div class="columns is-multiline">
                  <div class="column is-narrow ga-align-narrow-squad">
                    <!-- Campo -->
                    <div class="ga-squad" id="ga-squad">
                      <SquadView
                        @details="openDetails($event)"
                        :partida="partida"
                      ></SquadView>
                    </div>

                    <div class="ga-description">
                      <span>Campo de Defesa</span>
                      <b-icon pack="fas" icon="arrows-alt-h"></b-icon>
                      <span>Campo de Ataque</span>
                    </div>

                    <div class="ga-legendas centralize" v-if="$mq == 'sm'">
                      <label>Legendas</label>

                      <div
                        class="centralize-row"
                        :style="{ marginTop: '0.5em' }"
                      >
                        <div>
                          <div class="ga-leg-align-items">
                            <div class="ga-leg-rd ga-leg-conq"></div>
                            <span>Conquistado (CON)</span>
                          </div>
                          <div class="ga-leg-align-items">
                            <div class="ga-leg-rd ga-leg-ced"></div>
                            <span>Cedido (CED)</span>
                          </div>
                        </div>

                        <div class="ga-info-mobile">
                          <p>
                            <b-icon pack="fas" icon="hand-pointer"></b-icon>
                            Deslize o campo para a direita para ver o campo de
                            ataque e para a esquerda para ver o campo de defesa
                          </p>
                        </div>
                      </div>

                      <div :style="{ marginTop: '1em', textAlign: 'center' }">
                        <p>
                          Todos os <b>Gols</b> e <b>Assistências</b> se referem
                          apenas as partidas válidas pelo <b>Cartola FC</b>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="ga-content-filter">
                      <Filters :partida="partida"></Filters>
                    </div>

                    <div class="ga-legendas" v-if="$mq != 'sm'">
                      <label>Legendas</label>

                      <div :style="{ marginTop: '0.5em' }">
                        <div class="ga-leg-align-items">
                          <div class="ga-leg-rd ga-leg-conq"></div>
                          <span>Conquistado (CON)</span>
                        </div>
                        <div class="ga-leg-align-items">
                          <div class="ga-leg-rd ga-leg-ced"></div>
                          <span>Cedido (CED)</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="$mq != 'sm'" :style="{ marginTop: '1em' }">
                      <p>
                        Todos os <b>Gols</b> e <b>Assistências</b> se referem
                        apenas as partidas válidas pelo <b>Cartola FC</b>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Exibindo as partidas nesta coluna apenas se for desktop -->
          <div class="column" v-if="$mq != 'sm'">
            <div class="ga-container has-background-bluedark2">
              <Fixtures
                @getPartida="handlerPartida($event)"
                @clean="cleanPartida()"
              />
            </div>
          </div>
        </div>
      </div>

      <b-modal :active.sync="modal.detalhes" :width="500">
        <Detalhes :title="'Jogadores'" :info="modal_infos"></Detalhes>
      </b-modal>
    </BasePage>
  </BaseLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";

import SquadView from "@/components/helpers/field/Squad";
import Fixtures from "@/apps/Panel/components/GoalsAndAssists/Fixtures";
import Filters from "@/apps/Panel/components/GoalsAndAssists/Filters";
import Detalhes from "@/apps/Panel/components/GoalsAndAssists/Detalhes";

import FinalizacoesMixin from "@/modules/requests/Finalizacoes";
import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

export default {
  name: "GolasAndAssists",
  mixins: [FinalizacoesMixin, championshipMixins],
  components: {
    SquadView,
    Fixtures,
    Filters,
    Detalhes,
    BaseLayout,
    BasePage,
  },
  data() {
    return {
      partida: {},
      modal: {
        detalhes: false,
      },
      modal_infos: null,
    };
  },
  computed: {
    ...mapGetters(["mercado"]),
  },
  async created() {
    let loader = this.$loading.show();
    await this.loadChampionships();
    this.setDefaultToBrazilChampionship();

    Promise.all([this.getPosicoes(), this.getFinalizacoes()])
      .catch((err) => this.handlerErr(err, loader))
      .then(() => {
        loader.hide();
      });
  },
  mounted() {
    if (this.$mq == "sm") {
      let el = document.getElementById("ga-squad");
      el.scrollLeft = 600;
    }
  },
  methods: {
    ...mapActions(["getPosicoes", "getMercado"]),
    handlerPartida(partida) {
      this.partida = partida;
    },
    cleanPartida() {
      this.partida = {};
    },
    openDetails(data) {
      let aux = [];
      Object.keys(data).map((e) => {
        aux.push(data[e]);
      });

      this.modal_infos = aux;
      this.modal.detalhes = true;
    },
  },
};
</script>

<style scoped lang="scss">
.ga-content-section {
  max-width: 97vw;
}
.ga-hd-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ga-hd-items h2 {
  font-size: 1.2em;
  font-weight: bold;
}

@media (min-width: 600px) and (max-width: 1440px) {
  .ga-h2-filtros {
    display: none;
  }
  .ga-align-column {
    width: 74% !important;
  }
  .ga-squad {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ga-align-narrow-squad {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .ga-hd-items {
    justify-content: center !important;
    align-items: center;
  }
  .ga-squad {
    overflow-x: auto;
  }
  .ga-content {
    padding: 0.75em !important;
  }
  .ga-description {
    width: 100% !important;
  }
  .ga-legendas {
    width: 100% !important;
  }
  .ga-info-mobile {
    font-size: 0.85em;
    opacity: 0.4;
    width: 50%;
    margin-left: 2em;
  }
  .ga-info-mobile p {
    margin: 0 !important;
  }
}
.ga-container {
  border-radius: 4px !important;
}
.ga-divider {
  width: 100%;
  height: 1px;
  clear: both;
  background-color: rgba(0, 0, 0, 0.378);
}
.ga-content {
  padding: 1.5em;
}
.ga-description {
  width: 60%;
  margin: 0.5em auto;
  opacity: 0.5;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ga-legendas {
  width: 250px;
  margin-top: 1em;
}
.ga-legendas label {
  margin-bottom: 1em;
}
.ga-leg-align-items {
  margin-bottom: 0.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ga-leg-align-items:last-child {
  margin-bottom: 0;
}
.ga-leg-align-items span {
  font-size: 1em;
  color: rgb(255, 255, 255);
}
.ga-leg-rd {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 0.5em;
}
.ga-leg-ced {
  border: 1px solid #b84c40;
  background-color: #e3766b;
}
.ga-leg-conq {
  border: 1px solid #d5902a;
  background-color: #ffcb3b;
}
</style>
