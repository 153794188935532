import axios from "axios";
import tokenService from "./tokenService";

const http = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.VUE_APP_ENDPOINT,
  },
});

http.interceptors.request.use(async (config) => {
  const token = tokenService.get();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

http.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);

export default http;
