<template>
  <div class="view">
    <div class="view-content">
      <!-- Emblemas de Fundo -->
      <div
        v-if="viewFixture"
        class="view-side-ced"
        :style="{
          backgroundImage: `url(${
            origem === 'cedido_conquistado'
              ? partida_selecionada.clubes.casa.escudo
              : partida_selecionada.clubes.fora.escudo
          })`,
        }"
      >
        <p>
          {{
            origem === "cedido_conquistado"
              ? partida_selecionada.clubes.casa.time
              : partida_selecionada.clubes.fora.time
          }}
        </p>
      </div>
      <div
        v-if="viewFixture"
        class="view-side-con"
        :style="{
          backgroundImage: `url(${
            origem === 'conquistado_cedido'
              ? partida_selecionada.clubes.casa.escudo
              : partida_selecionada.clubes.fora.escudo
          })`,
        }"
      >
        <p>
          {{
            origem === "conquistado_cedido"
              ? partida_selecionada.clubes.casa.time
              : partida_selecionada.clubes.fora.time
          }}
        </p>
      </div>

      <!-- Linhas -->
      <div class="view-line"></div>
      <div class="view-circle"></div>
      <div class="view-circle-mini"></div>
      <div class="view-area-1"></div>
      <div class="view-area-sm-1"></div>
      <div class="view-area-2"></div>
      <div class="view-area-sm-2"></div>
      <div class="view-semi-circle-1"></div>
      <div class="view-semi-circle-2"></div>

      <div v-if="viewRender">
        <div v-if="finLoading" class="pos-loading">
          <div class="circle"></div>
        </div>

        <div v-else>
          <div
            v-for="zone of zones"
            :key="'zone-' + zone"
            :class="`zone zone-${zone}`"
            :style="{ transition: 'all .2s ease-in-out' }"
          >
            <div v-for="sv of view_scout" :key="sv + zone">
              <div
                v-if="
                  getFinDados &&
                    sv in getFinDados &&
                    zone in getFinDados[sv] &&
                    getFinDados[sv][zone] &&
                    getFinDados[sv][zone].length > 0
                "
                :kpi="sv"
                :class="
                  `kpi kpi-${
                    getFinDados[sv][zone].length > 20
                      ? 'lg'
                      : getFinDados[sv][zone].length >= 10 &&
                        getFinDados[sv][zone].length <= 20
                      ? 'md'
                      : 'sm'
                  }`
                "
                @click="openDetails(getFinDados[sv][zone])"
              >
                {{ getFinDados[sv][zone].length }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SquadView",
  props: {
    partida: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      viewRender: false,
      zones: [
        11,
        13,
        12,
        14,
        21,
        22,
        23,
        24,
        32,
        33,
        41,
        42,
        43,
        44,
        52,
        53,
        61,
        62,
        63,
        64,
      ],
    };
  },
  watch: {
    getFinDados: {
      handler: function(n, o) {
        if ("conquistado" in n && "cedido" in n && !this.finLoading) {
          this.viewRender = true;
        } else {
          this.viewRender = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "partida_selecionada",
      "fin_view",
      "getFinDados",
      "fin_params",
      "finLoading",
    ]),
    viewFixture() {
      return this.partida_selecionada ? true : false;
    },
    origem() {
      return this.fin_params.origem;
    },
    view_scout() {
      if (this.fin_view.conquistado && this.fin_view.cedido)
        return ["cedido", "conquistado"];
      if (this.fin_view.conquistado) return ["conquistado"];
      if (this.fin_view.cedido) return ["cedido"];
    },
    params() {
      let obj = {};

      try {
        if (origem === "conquistado_cedido") {
          return {
            conquistado: this.partida_selecionada.clubes.casa,
            cedido: this.partida_selecionada.clubes.fora,
          };
        } else if (origem === "cedido_conquistado") {
          return {
            conquistado: this.partida_selecionada.clubes.fora,
            cedido: this.partida_selecionada.clubes.casa,
          };
        }
      } catch (err) {
        console.log(err);
      }
      return obj;
    },
  },
  methods: {
    openDetails(data) {
      this.$emit("details", Object.assign({}, data));
    },
  },
};
</script>

<style scoped>
@media (min-width: 600px) and (max-width: 1440px) {
  .view {
    width: 830px !important;
    height: 470px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1720px) {
  .view {
    width: 800px !important;
    height: 550px !important;
  }
}
@media (max-width: 600px) {
  .view {
    width: 670px !important;
    height: 400px !important;
  }
}
.view {
  width: 900px;
  height: 600px;
  border-radius: 4px;
  position: relative;
  padding: 1em;
  background: linear-gradient(
    180deg,
    rgb(51, 167, 117) 10%,
    rgb(41, 118, 47) 100%
  );
}
.view-content {
  position: relative;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  border: 2px solid white;
}
.view-side-ced {
  position: absolute;
  width: 40%;
  left: 10%;
  top: 0%;
  bottom: 0%;
  opacity: 0.1;
  text-align: center;
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
}
.view-side-con {
  position: absolute;
  width: 40%;
  right: 10%;
  top: 0%;
  bottom: 0%;
  opacity: 0.1;
  text-align: center;
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
}
.view-side-con p,
.view-side-ced p {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  font-weight: bold;
  font-size: 2em;
  transform: translate(-50%, 150%);
}
.view-line {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.view-circle {
  position: absolute;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  border: 2px solid white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.view-circle-mini {
  z-index: 10;
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1800px) {
  .view-semi-circle-1 {
    position: absolute;
    left: 17.5%;
    top: 45%;
    bottom: 45%;
    width: 10%;
    height: 40px; /* as the half of the width */
    border-top-left-radius: 55px; /* 100px of height + 10px of border */
    border-top-right-radius: 55px; /* 100px of height + 10px of border */
    border: 2px solid rgb(255, 255, 255);
    border-bottom: 0;
    transform: rotate(90deg);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .view-semi-circle-2 {
    position: absolute;
    right: 17.5%;
    top: 45%;
    bottom: 45%;
    width: 10%;
    height: 40px; /* as the half of the width */
    border-top-left-radius: 55px; /* 100px of height + 10px of border */
    border-top-right-radius: 55px; /* 100px of height + 10px of border */
    border: 2px solid rgb(255, 255, 255);
    border-bottom: 0;
    transform: rotate(-90deg);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
@media (max-width: 600px) {
  .view-semi-circle-1 {
    left: 18%;
  }
  .view-semi-circle-2 {
    right: 18%;
  }
}

@media (min-width: 1800px) {
  .view-semi-circle-1 {
    position: absolute;
    left: 17.7%;
    top: 45%;
    bottom: 45%;
    width: 10%;
    height: 50px; /* as the half of the width */
    border-top-left-radius: 65px; /* 100px of height + 10px of border */
    border-top-right-radius: 65px; /* 100px of height + 10px of border */
    border: 2px solid rgb(255, 255, 255);
    border-bottom: 0;
    transform: rotate(90deg);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .view-semi-circle-2 {
    position: absolute;
    right: 17.7%;
    top: 45%;
    bottom: 45%;
    width: 10%;
    height: 50px; /* as the half of the width */
    border-top-left-radius: 65px; /* 100px of height + 10px of border */
    border-top-right-radius: 65px; /* 100px of height + 10px of border */
    border: 2px solid rgb(255, 255, 255);
    border-bottom: 0;
    transform: rotate(-90deg);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.view-area-1 {
  position: absolute;
  left: 0;
  top: 20%;
  bottom: 20%;
  width: 20%;
  border-radius: 0 2px 2px 0;
  border: 2px solid white;
  border-left: none;
}
.view-area-2 {
  position: absolute;
  right: 0;
  top: 20%;
  bottom: 20%;
  width: 20%;
  border-radius: 2px 0 0 2px;
  border: 2px solid white;
  border-right: none;
}
.view-area-sm-1 {
  position: absolute;
  left: 0;
  top: 35%;
  bottom: 35%;
  width: 10%;
  border-radius: 0 2px 2px 0;
  border: 2px solid white;
  border-left: none;
}
.view-area-sm-2 {
  position: absolute;
  right: 0;
  top: 35%;
  bottom: 35%;
  width: 10%;
  border-radius: 2px 0 0 2px;
  border: 2px solid white;
  border-right: none;
}

.pos-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .view {
    /* width: 100%; */
    /* max-width: 700px; */
    /* height: 450px; */
  }
}

.zone {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%230000002A' stroke-width='4' stroke-dasharray='2%2c 20' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e"); */
}
.kpi {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0.25em;
}
.kpi-sm {
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.kpi-sm:hover::before {
  content: "";
  position: absolute;
  /* top: -15.5%;
  left: -15.5%; */
  animation: rotate 20s linear infinite;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2323FFFFFF' stroke-width='4' stroke-dasharray='10%2c 9' stroke-dashoffset='69' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 50%;
  background-color: transparent;
}
.kpi-md {
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  cursor: pointer;
  width: 55px;
  height: 55px;
}
.kpi-md:hover::before {
  content: "";
  position: absolute;
  /* top: -11.5%;
  left: -11.5%; */
  animation: rotate 20s linear infinite;
  cursor: pointer;
  width: 65px;
  height: 65px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2323FFFFFF' stroke-width='4' stroke-dasharray='10%2c 9' stroke-dashoffset='69' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 50%;
  background-color: transparent;
}
.kpi-lg {
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  cursor: pointer;
  width: 70px;
  height: 70px;
}
.kpi-lg:hover::before {
  content: "";
  position: absolute;
  /* top: -9.5%;
  left: -9.5%; */
  animation: rotate 20s linear infinite;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2323FFFFFF' stroke-width='4' stroke-dasharray='10%2c 9' stroke-dashoffset='69' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 50%;
  background-color: transparent;
}

/* Zonas defensivas cedido */
.zone-11 {
  left: 0;
  top: 0;
  width: 20%;
  height: 20%;
  border-right: 1px dotted rgba(17, 17, 17, 0.261);
}
.zone-12 {
  left: 0;
  top: 35%;
  width: 10%;
  bottom: 35%;
}
.zone-13 {
  left: 0;
  top: 20%;
  width: 20%;
  bottom: 20%;
  display: flex;
  align-items: flex-end;
  padding-right: 1em;
}
.zone-14 {
  left: 0;
  bottom: 0;
  width: 20%;
  height: 20%;
  border-right: 1px dotted rgba(17, 17, 17, 0.261);
}

.kpi[kpi="conquistado"] {
  border-radius: 50%;
  border: 1px solid #d5902a;
  background-color: #ffcb3b;
}
.kpi[kpi="cedido"] {
  border-radius: 50%;
  border: 1px solid #b84c40;
  background-color: #e3766b;
}

/* Zonas defensivas conquistado */
.zone-61 {
  right: 0;
  top: 0;
  width: 20%;
  height: 20%;
  border-left: 1px dotted rgba(17, 17, 17, 0.261);
}
.zone-63 {
  right: 0;
  top: 35%;
  width: 10%;
  bottom: 35%;
}
.zone-62 {
  right: 0;
  top: 20%;
  width: 20%;
  bottom: 20%;
  display: flex;
  align-items: flex-start;
  padding-left: 1em;
}
.zone-64 {
  right: 0;
  bottom: 0;
  width: 20%;
  height: 20%;
  border-left: 1px dotted rgba(17, 17, 17, 0.261);
}

/* laterais cedido */
.zone-21 {
  left: 20%;
  top: 0;
  height: 20%;
  width: 30%;
  border-bottom: 1px dotted rgba(17, 17, 17, 0.261);
}
.zone-24 {
  left: 20%;
  bottom: 0;
  height: 20%;
  width: 30%;
  border-top: 1px dotted rgba(17, 17, 17, 0.261);
}

/* laterais conquistado */
.zone-41 {
  right: 20%;
  top: 0;
  height: 20%;
  width: 30%;
  border-bottom: 1px dotted rgba(17, 17, 17, 0.261);
}
.zone-44 {
  right: 20%;
  bottom: 0;
  height: 20%;
  width: 30%;
  border-top: 1px dotted rgba(17, 17, 17, 0.261);
}

/* meio campo cedido */
.zone-22 {
  left: 20%;
  top: 20%;
  height: 30%;
  width: 15%;
  border-right: 1px dotted rgba(17, 17, 17, 0.261);
  border-bottom: 1px dotted rgba(17, 17, 17, 0.261);
}
.zone-23 {
  left: 20%;
  bottom: 20%;
  height: 30%;
  width: 15%;
}
.zone-32 {
  left: 35%;
  top: 20%;
  height: 30%;
  width: 15%;
}
.zone-33 {
  left: 35%;
  bottom: 20%;
  height: 30%;
  width: 15%;
  border-left: 1px dotted rgba(17, 17, 17, 0.261);
  border-top: 1px dotted rgba(17, 17, 17, 0.261);
}

/* meio campo conquistado */
.zone-52 {
  right: 20%;
  top: 20%;
  height: 30%;
  width: 15%;
}
.zone-53 {
  right: 20%;
  bottom: 20%;
  height: 30%;
  width: 15%;
  border-left: 1px dotted rgba(17, 17, 17, 0.261);
  border-top: 1px dotted rgba(17, 17, 17, 0.261);
}
.zone-42 {
  right: 35%;
  top: 20%;
  height: 30%;
  width: 15%;
  border-right: 1px dotted rgba(17, 17, 17, 0.261);
  border-bottom: 1px dotted rgba(17, 17, 17, 0.261);
}
.zone-43 {
  right: 35%;
  bottom: 20%;
  height: 30%;
  width: 15%;
}
</style>
