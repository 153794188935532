import ConfigureService from "@/services/configureService";

export default class ScoutsAnalysisService extends ConfigureService {
  constructor() {
    super("atletas");
  }

  getCards({ region, championship }, userId) {
    return this.apiStats.get(
      `${region}/${championship}/analise-scouts/${userId}`
    );
  }

  getAnalysis({ region, championship }, query) {
    return this.apiStats.get(
      `${region}/${championship}/analise-scouts?${query}`
    );
  }

  createCard({ region, championship }, userId, data) {
    return this.apiStats.post(
      `${region}/${championship}/analise-scouts/${userId}`,
      data
    );
  }

  updateCard({ region, championship }, userId, data) {
    return this.apiStats.put(
      `${region}/${championship}/analise-scouts/${userId}/${data.id}`,
      data
    );
  }

  deleteCard({ region, championship }, userId, cardId) {
    return this.apiStats.delete(
      `${region}/${championship}/analise-scouts/${userId}/${cardId}`
    );
  }

  getAnalysisDetails({ region, championship }, query) {
    return this.apiStats.get(
      `${region}/${championship}/analise-scouts/clubes-detalhes?${query}`
    );
  }
}
