import ConfigureService from "@/services/configureService";

export default class CommonService extends ConfigureService {
  constructor() {
    super("");
  }

  getChampionships() {
    return this.apiStats.get(`/campeonatos`);
  }

  getMatches({ region, championship }, round) {
    return this.apiStats.get(`${region}/${championship}/partidas/${round}`);
  }

  getPositions({ region, championship }) {
    return this.apiStats.get(`${region}/${championship}/posicoes`);
  }

  getScouts() {
    return this.api.get("/atletas/scouts");
  }
}
