import * as AmplitudeAPI from "@amplitude/node";

const AMPLITUDE_API_KEY = process.env.VUE_APP_AMPLITUDE_KEY;

export default class Amplitude {
  constructor(userProperties) {
    this.client = AmplitudeAPI.init(AMPLITUDE_API_KEY);
    this.user = userProperties;
  }

  createEvent(eventName, event_properties) {
    this.client.logEvent({
      user_id: this.user.email,
      event_type: eventName,
      platform: "Web",
      event_properties,
      user_properties: this.user,
    });
  }
}
