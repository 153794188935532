<template>
  <b-dropdown
    class="fa-dropdown"
    ref="fadropdown"
    position="is-bottom-left"
    :close-on-click="false"
    :can-close="false"
    :expanded="$mq == 'sm'"
  >
    <div class="atl-fa-content" slot="trigger">
      <b-tooltip
        label="Clique para adicionar Filtros"
        :position="$mq === 'sm' ? 'is-top' : 'is-bottom'"
        type="is-pigeon"
      >
        <div class="fa-box-filter">
          <b-icon icon="filter" pack="fas"></b-icon>
          <span>Filtros</span>
          <b-tag
            v-if="sumFilters > 0"
            class="fa-tag"
            rounded
            type="is-darkblue"
            >{{ sumFilters }}</b-tag
          >
        </div>
      </b-tooltip>
    </div>

    <div
      class="fa-container"
      :style="`max-height: ${heightFilter}; max-width: ${widthFilter};`"
    >
      <div class="fa-header">
        <div v-if="$mq === 'sm'" class="fa-close" @click="close">
          <b-icon icon="times-circle" pack="fas"></b-icon>
        </div>
        <h3 class="fa-title">Filtrar Atletas</h3>
      </div>

      <div class="fa-content">
        <div class="columns is-multiline is-variable is-2">
          <div class="column">
            <div class="fa-filter-field">
              <b-field label="Atleta">
                <b-input
                  placeholder="Nome do Atleta"
                  v-model="filter['apelido_atleta.contains']"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div v-if="isBrazilChampionship" class="column">
            <div class="fa-filter-field">
              <b-field label="Status">
                <v-select
                  class="form-select multiple-select"
                  multiple
                  placeholder="Status"
                  :options="playerStatus"
                  v-model="filter['ds_status_atleta.in']"
                ></v-select>
              </b-field>
            </div>
          </div>
          <div class="column">
            <div class="fa-filter-field">
              <b-field label="Posições">
                <v-select
                  class="form-select multiple-select"
                  multiple
                  placeholder="Posições"
                  :options="positionsAbrev"
                  v-model="filter['cd_posicao_atleta.in']"
                ></v-select>
              </b-field>
            </div>
          </div>
          <div class="column is-full">
            <b-field label="Times">
              <div class="fa-clubs-content">
                <div>
                  <label class="fa-subtitle"
                    >Mandante
                    <b-button
                      size="is-small"
                      type="is-text"
                      @click="selectAllClubs('home')"
                      >Selecionar todos</b-button
                    ></label
                  >
                  <div
                    class="columns is-multiline is-mobile mt-1 justify-columns"
                  >
                    <div
                      v-for="club of homeClubs"
                      :key="club.abreviacao"
                      class="column is-narrow"
                    >
                      <div class="fa-club-box" @click="handlerClubSelect(club)">
                        <img :src="club.escudo" :alt="club.nome" width="35" />
                        <b-icon
                          v-if="validClubIsSelect(club.abreviacao)"
                          class="fa-icon-check centralize"
                          icon="check-circle"
                          pack="fas"
                          type="is-success"
                        ></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <label class="fa-subtitle"
                    >Visitantes
                    <b-button
                      size="is-small"
                      type="is-text"
                      @click="selectAllClubs('away')"
                      >Selecionar todos</b-button
                    ></label
                  >
                  <div
                    class="columns is-multiline is-mobile mt-1 justify-columns"
                  >
                    <div
                      v-for="club of awayClubs"
                      :key="club.abreviacao"
                      class="column is-narrow"
                    >
                      <div class="fa-club-box" @click="handlerClubSelect(club)">
                        <img :src="club.escudo" :alt="club.nome" width="35" />
                        <b-icon
                          v-if="validClubIsSelect(club.abreviacao)"
                          class="fa-icon-check centralize"
                          icon="check-circle"
                          pack="fas"
                          type="is-success"
                        ></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-field>
          </div>
          <div v-if="false" class="column is-full">
            <div class="fa-align-box">
              <b-field label="Preços C$">
                <b-slider
                  type="is-primary"
                  v-model="filter['preco']"
                  :min="0"
                  :max="25"
                  :step="1"
                  :custom-formatter="customFormatter"
                >
                  <b-slider-tick :value="0"></b-slider-tick>
                  <b-slider-tick :value="5"></b-slider-tick>
                  <b-slider-tick :value="10"></b-slider-tick>
                  <b-slider-tick :value="15"></b-slider-tick>
                  <b-slider-tick :value="20"></b-slider-tick>
                  <b-slider-tick :value="25"></b-slider-tick>
                </b-slider>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="group-bt-filter">
        <b-button class="fa-bt" type="is-secondary" @click="cleanFilters()"
          >Limpar Filtros</b-button
        ><b-button class="fa-bt" type="is-success" @click="submit()"
          >Filtrar</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import AtletasMixin from "@/modules/requests/Atletas";
import CommonService from "@/apps/Panel/services/commonService";
import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

const commonService = new CommonService();

export default {
  name: "Filtros",
  mixins: [AtletasMixin, championshipMixins],
  components: {
    "v-select": vSelect,
  },
  props: {
    filtersParams: {
      type: Object,
      required: false,
    },
    matches: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sumFilters: 0,
      filter: {
        "ds_status_atleta.in": [],
        "cd_posicao_atleta.in": [],
        "cd_clube_atleta.in": [],
      },
      mando: null,
      playerStatus: [],
      posicoes: [],
    };
  },
  computed: {
    ...mapGetters(["scouts"]),
    heightFilter() {
      if (this.$mq === "sm") {
        return "100%";
      }
      const screenHeight = window.screen.height - 150;
      const screenAvailable = screenHeight - (this.$mq == "sm" ? 30 : 150);
      return `${screenAvailable}px`;
    },
    widthFilter() {
      if (this.$mq === "sm") {
        return "100%";
      }
      const screenWidth = window.screen.width;
      const screenAvailable = screenWidth - (this.$mq == "sm" ? 30 : 200);
      return `${screenAvailable}px`;
    },
    homeClubs() {
      return this.matches.map((match) => match.mandante);
    },
    awayClubs() {
      return this.matches.map((match) => match.visitante);
    },
    positionsAbrev() {
      return this.posicoes.map((el) => el.abreviacao.toUpperCase());
    },
  },
  async created() {
    await this.fetchPositions();
    await this.getScouts();

    this.getPlayerStatus().then((response) => {
      this.playerStatus = response.map((e) => e.label);
    });

    Object.entries(this.filtersParams).forEach(([key, value]) => {
      this.filter[key] = value;
    });
  },
  methods: {
    ...mapActions(["getScouts"]),
    submit() {
      let filter = { ...this.filter };
      filter["cd_posicao_atleta.in"] = filter["cd_posicao_atleta.in"].map(
        (position) => {
          if (position === "DEF") return "ZAG";
          return position;
        }
      );

      this.$emit("result", { ...filter });
      this.$refs.fadropdown.toggle();
    },
    close() {
      this.$refs.fadropdown.toggle();
    },
    cleanFilters() {
      this.filter = {
        "ds_status_atleta.in": [],
        "cd_posicao_atleta.in": [],
        "cd_clube_atleta.in": [],
      };
      this.submit();
    },
    fetchPositions() {
      commonService
        .getPositions({
          region: this.getChampionship.regiao.id,
          championship: this.getChampionship.id,
        })
        .then((response) => {
          this.posicoes = response;
        });
    },
    selectAllClubs(mando) {
      if (mando === "home") {
        this.filter["cd_clube_atleta.in"] = this.homeClubs.map(
          (c) => c.abreviacao
        );
      } else {
        this.filter["cd_clube_atleta.in"] = this.awayClubs.map(
          (c) => c.abreviacao
        );
      }
    },
    customFormatter(value) {
      if (value == 25) {
        return `C$ ${value}+`;
      }
      return `C$ ${value}`;
    },
    handlerClubSelect(club) {
      const indexClubSelected = this.filter["cd_clube_atleta.in"].findIndex(
        (clubAbrev) => clubAbrev === club.abreviacao
      );

      if (indexClubSelected !== -1) {
        this.filter["cd_clube_atleta.in"].splice(indexClubSelected, 1);
      } else {
        this.filter["cd_clube_atleta.in"].push(club.abreviacao);
      }

      this.filter["cd_clube_atleta.in"] = [
        ...this.filter["cd_clube_atleta.in"],
      ];
    },
    validClubIsSelect(clubAbrev) {
      return this.filter["cd_clube_atleta.in"].find((c) => c === clubAbrev);
    },
  },
};
</script>

<style lang="scss">
.justify-columns {
  justify-content: space-between;
}
@media (min-width: 758px) {
  .fa-filter-field {
    min-width: 220px;
  }
  .fa-align-box {
    margin-top: 1em;
  }
}

@media (max-width: 658px) {
  .justify-columns {
    justify-content: center !important;
  }
}
</style>
