import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {
      Finalizacoes: {},
      loadingFinalizacoes: false,
      loadingTipoJogadas: false,
      TipoJogadas: []
    }
  },
  computed: {
    ...mapGetters(['fin_params', 'partida_selecionada'])
  },
  methods: {
    ...mapActions([ 'setDados', 'setFinLoading' ]),
    async getFinalizacoes () {
      try {

        this.loadingFinalizacoes = true
        this.setFinLoading(true)

        let param = {}

        if (this.fin_params.bola.length > 0) param.bola = this.fin_params.bola
        if (this.fin_params.tipo_jogada.length > 0) param.tipo_jogada = this.fin_params.tipo_jogada
        if (this.fin_params.posicao.length > 0) param.posicao = this.fin_params.posicao.map(e => e.toLowerCase())

        param.scout = this.fin_params.scout
        param.jogos = this.fin_params.jogos

        if (this.fin_params.origem == 'conquistado_cedido') {
          param.cd_clube_conq = this.partida_selecionada.clubes.casa.sigla
          param.cd_clube_ced = this.partida_selecionada.clubes.fora.sigla
        }
        if (this.fin_params.origem == 'cedido_conquistado') {
          param.cd_clube_conq = this.partida_selecionada.clubes.fora.sigla
          param.cd_clube_ced = this.partida_selecionada.clubes.casa.sigla
        }
        
        let res = (await this.$http.post(url + '/finalizacoes', {
          data: {...param}
        })).data.data

        this.Finalizacoes = res
        this.setDados(res)

        this.loadingFinalizacoes = false
        this.setFinLoading(false)

      } catch (err) {
        this.loadingFinalizacoes = false
        console.log(err)
      }
    },
    async getTipoJogadas () {
      try {
  
        this.loadingTipoJogadas = true
  
        let res = (await this.$http.get(url + '/finalizacoes/tipo-jogadas')).data.data
        this.TipoJogadas = res

        this.loadingTipoJogadas = false
  
      } catch (err) {
        console.log(err)
        this.loadingTipoJogadas = false
      }
  
    }
  }
}