<template>
  <vc-donut
    :background="background"
    :foreground="foreground"
    :size="size"
    :unit="unit"
    :thickness="thickness"
    :has-legend="hasLegend"
    :legend-placement="legendPlacement"
    :sections="sections"
    :total="total"
    :start-angle="startAngle"
    :auto-adjust-text-size="autoAdjustTextSize"
    @section-click="$emit('section-click')"
  >
    <h1>{{ description }}</h1>
  </vc-donut>
</template>

<script>
export default {
  name: "DonutChart",
  props: {
    sections: {
      type: Array,
      retuired: true,
    },
    description: {
      type: String,
      default: "",
    },
    autoAdjustTextSize: {
      type: Boolean,
      default: true,
    },
    startAngle: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 100,
    },
    legendPlacement: {
      type: String,
      default: "top",
    },
    hasLegend: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "#3f435e",
    },
    foreground: {
      type: String,
      default: "grey",
    },
    size: {
      type: Number,
      default: 200,
    },
    unit: {
      type: String,
      default: "px",
    },
    thickness: {
      type: Number,
      default: 40,
    },
  },
};
</script>

<style scoped>
h1 {
  transform: scale(3);
}
</style>
