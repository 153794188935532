var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"overview-analysis"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.analysis.descricao)+" "),_c('b-tag',{attrs:{"type":("is-position-" + (_vm.analysis.posicao))}},[_vm._v(_vm._s(_vm.analysis.posicao.toUpperCase()))])],1),_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.filters),function(ref){
var label = ref.label;
var field = ref.field;
var template = ref.template;
var clubs = ref.clubs;
return _c('div',{key:field,class:("column " + template)},[_c('h3',[_vm._v(_vm._s(label))]),_c('div',{class:("wrapper wp-" + field)},_vm._l((clubs),function(club,index){return _c('div',{key:("clubs-analysis-" + (club.id)),class:("club_box box-" + index + "-" + field)},[_c('div',{staticClass:"club-box-column"},[_c('div',{staticClass:"club-box-row"},[_c('img',{attrs:{"src":club.escudo}})]),_c('p',{staticClass:"club-box-name"},[_vm._v(_vm._s(club.abreviacao))])]),_c('div',{staticClass:"club-box-column"},[_c('div',{staticClass:"club-box-row"},[_c('span',{staticClass:"club-box-values-scout"},[_vm._v(_vm._s(club.valor))])]),_c('p',{staticClass:"club-box-name"},[_vm._v(_vm._s(_vm.analysis.scout))])]),(_vm.type === 'recurrence')?_c('div',{staticClass:"club-box-column"},[_c('div',{staticClass:"chart"},[_c('DonutChartVue',{attrs:{"sections":[
                  {
                    value: club.porcentagem,
                    color: _vm.handleColorByValue(club.porcentagem),
                  } ],"size":_vm.getPropertiesChart(field, index).size,"background":_vm.getPropertiesChart(field, index).background,"foreground":"#6a788f","thickness":25,"description":((club.porcentagem) + "%")}})],1)]):_vm._e()])}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }