import { mapActions, mapGetters } from "vuex";
import CommonService from "../services/commonService";

const commonService = new CommonService();

export default {
  data() {
    return {
      championships: [],
    };
  },
  computed: {
    ...mapGetters("Championships", ["getChampionship"]),
    isBrazilChampionship() {
      return this.getChampionship?.regiao?.id === 5;
    },
  },
  methods: {
    ...mapActions("Championships", ["setChampionship"]),
    async loadChampionships() {
      const response = await commonService.getChampionships();
      this.championships = response;

      const championship = localStorage.getItem("e10_championship");
      if (championship) {
        const parsedChampionship = JSON.parse(championship);
        const championshipData = this.championships.find(
          (c) => c.id === parsedChampionship.id
        );

        this.setChampionship(championshipData);
      } else {
        const [championshipSelected] = response.filter(
          (c) => c.id == 648 && c.regiao.id == 5
        );
        if (championshipSelected) {
          this.setChampionship(championshipSelected);
        } else {
          this.setChampionship(response[0]);
        }
      }
    },
    setDefaultToBrazilChampionship() {
      const brazilChampionship = this.championships.find(
        (c) => c.id == 648 && c.regiao.id == 5
      );
      if (brazilChampionship) {
        this.setChampionship(brazilChampionship);
      }
    },
  },
};
