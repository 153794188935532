<template>
  <b-navbar wrapper-class="app-panel-alignment-navbar" fixed-top>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/painel' }">
        <img
          src="../../../assets/imagens/logotipo_green_10.png"
          alt="Escala 10"
          :style="$mq == 'sm' ? 'width: 120px;' : ''"
        />
      </b-navbar-item>
      <b-navbar-item v-if="isMobile">
        <div class="menu-group-item">
          <div class="app-select-championship-flag">
            <div>
              <img
                :src="getChampionship.logo"
                :alt="getChampionship.descricao"
              />
            </div>
          </div>
        </div>
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item v-if="isPageForDefaultChampionship">
        <div class="menu-group-item">
          <div class="app-select-championship-flag">
            <div>
              <img
                :src="getChampionship.logo"
                :alt="getChampionship.descricao"
              />
            </div>
          </div>
          {{ getChampionship.descricao }}
        </div>
      </b-navbar-item>
      <b-navbar-dropdown v-else collapsible>
        <template slot="label">
          <div class="menu-group-item">
            <div class="app-select-championship-flag">
              <div>
                <img
                  :src="getChampionship.logo"
                  :alt="getChampionship.descricao"
                />
              </div>
            </div>
            {{ getChampionship.descricao }}
          </div>
        </template>
        <b-navbar-item
          v-for="championship of championships"
          :key="championship.slug"
          @click="handleSelectChampionship(championship)"
        >
          <div class="menu-group-item">
            <div class="app-select-championship-flag">
              <div>
                <img :src="championship.logo" :alt="championship.descricao" />
              </div>
            </div>
            <div class="championship-description">
              <p>{{ championship.descricao }}</p>
              <small>{{
                `${championship.regiao.descricao} ${championship.temporada}`
              }}</small>
            </div>
          </div>
        </b-navbar-item>
      </b-navbar-dropdown>
      <div class="app-panel-nav-align-buttons">
        <b-button
          :expanded="isMobile"
          icon-pack="fas"
          icon-left="trophy"
          type="is-primary"
          size="is-small"
          tag="a"
          target="_blank"
          href="https://ligas.escala10.com.br/"
        >
          <b>Ligas Escala 10</b>
        </b-button>
      </div>
      <RestrictAccess
        v-for="(item, index) of menuItems"
        :key="`menu-item-${index}`"
        :slug="item.p"
        appName="painel"
      >
        <b-navbar-item
          v-if="!item.children"
          tag="router-link"
          :to="item.url"
          :active="isActiveItem(item)"
        >
          <div class="menu-group-item">
            <b-icon pack="fas" :icon="item.icon"></b-icon>
            {{ item.name }}
          </div>
        </b-navbar-item>

        <b-navbar-dropdown v-else :arrowless="isMobile">
          <template slot="label">
            <div class="menu-group-item">
              <b-icon pack="fas" :icon="item.icon"></b-icon>
              {{ item.name }}
            </div>
          </template>

          <RestrictAccess
            v-for="(child, indexChild) of item.children"
            :key="`menu-item-child-${indexChild}`"
            :slug="child.p"
            appName="painel"
          >
            <b-navbar-item tag="router-link" :to="child.url">
              <div class="menu-group-item">
                <b-icon pack="fas" :icon="child.icon"></b-icon>
                {{ child.name }}
              </div>
            </b-navbar-item>
          </RestrictAccess>
        </b-navbar-dropdown>
      </RestrictAccess>
    </template>

    <template slot="end">
      <b-navbar-item>
        <Notification></Notification>
      </b-navbar-item>
      <b-navbar-dropdown collapsible>
        <template slot="label">
          <div class="menu-group-item">
            <b-icon pack="fas" icon="user"></b-icon>
            {{ userInfo.nome }}
          </div>
        </template>

        <b-navbar-item @click="gotoMeuDados">
          <div class="menu-group-item">
            <b-icon pack="fas" icon="id-card"></b-icon>
            Meus Dados
          </div>
        </b-navbar-item>
      </b-navbar-dropdown>
      <div class="app-panel-nav-align-buttons">
        <b-button
          logout
          :expanded="isMobile"
          icon-pack="fas"
          icon-right="sign-out-alt"
          type="is-magenta"
          size="is-small"
          @click="signOutLogin"
        >
          Sair
        </b-button>
      </div>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Menu } from "@/shared/Nav";
import RestrictAccess from "@/components/restrictAccess/restrictAccess";
import Notification from "@/components/header/components/Notification";
import ModalMeusDados from "@/apps/Auth/modals/MeusDados";

export default {
  name: "Navbar",
  components: {
    RestrictAccess,
    Notification,
  },
  props: {
    championships: {
      type: Array,
      required: true,
    },
    isPageForDefaultChampionship: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItems: Menu("painel"),
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters("Championships", ["getChampionship"]),
  },
  methods: {
    ...mapActions(["signOut"]),
    ...mapActions("Championships", ["setChampionship"]),
    signOutLogin() {
      this.$buefy.dialog.confirm({
        title: "Sair",
        message: "Você tem certeza que deseja <b>sair</b> agora?",
        confirmText: "Sim, vou sair",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          await this.signOut();
          this.$router.push({ path: "/login" });
        },
      });
    },
    handleSelectChampionship(championship) {
      localStorage.setItem("e10_championship", JSON.stringify(championship));
      this.setChampionship({ ...championship });
      this.$emit("change-championship");
    },
    isActiveItem(item) {
      return this.$route.path === item.url;
    },
    gotoMeuDados() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMeusDados,
        hasModalCard: false,
        fullScreen: this.isMobile,
        canCancel: false,
        props: {
          fluid: this.isMobile,
          ...this.propsParams,
        },
        events: {
          error: () => {},
          close: () => {},
        },
      });
    },
  },
};
</script>

<style lang="scss">
.app-panel-alignment-navbar {
  @media (min-width: 768px) {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }

  .app-select-championship-flag {
    margin-right: 0.5em;
    height: 30px;
    width: 30px;

    div {
      border-radius: 50%;
      background-color: #cfcfff65;
      overflow: hidden;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      height: 20px;
    }
  }
  .championship-description {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 0;
    }
    small {
      font-size: 0.7em;
      opacity: 0.5;
    }
  }

  .app-panel-nav-align-buttons {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1em;

    @media (max-width: 768px) {
      .button {
        margin: 0.75em 0;

        &[logout] {
          margin-top: 1em;
        }
      }
    }
  }

  .menu-group-item {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 0.5em;
      font-size: 9px;
    }
  }

  .restrictAccess {
    height: 100%;

    .restrict-box {
      height: 100%;
      display: flex;
      align-items: center;

      .lock-box-blocked {
        border-radius: 4px;
      }
    }

    .restrict-access-enabled {
      height: 100%;
      display: flex;
      align-items: center;

      a,
      .navbar-item,
      .navbar-item a {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
