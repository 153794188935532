<template>
  <div class="player-column">
    <div class="player-team-shield">
      <img :src="player.clube.escudo" />
    </div>

    <div class="player-image">
      <img :src="player.foto" :alt="player.nome" />
    </div>
    <div class="player-container-info">
      <div class="columns is-mobile is-gapless">
        <div class="column">
          <div class="about-player">
            <img
              v-if="hasIconStatus"
              class="player-column-status"
              :src="getStatusPlayer()"
            />
            <div class="player-labels">
              <p class="player-title">{{ player.nome }}</p>
              <p class="player-subtitle">
                <span>{{ player.posicao.abreviacao }}</span>
              </p>
            </div>
          </div>
          <p v-if="isBrazilChampionship" class="player-field-value-2">
            C$ {{ player.preco | decimals }}
          </p>
          <div class="player-match">
            <div class="level is-mobile match-column">
              <div class="level-left">
                <img :src="player.confronto.mandante.escudo" />
              </div>
              <div class="level-item">
                <b style="font-size: 0.7em">X</b>
              </div>
              <div class="level-right">
                <img :src="player.confronto.visitante.escudo" />
              </div>
            </div>
          </div>
        </div>
        <div id="player-columns-scouts" class="column is-two-thirds">
          <div class="columns is-multiline is-mobile is-gapless">
            <div v-if="isGeralScouts" class="column is-one-quarter">
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ player.media[player.mandoDeJogo] | decimals }}
                </p>
                <p class="player-field-name">Média {{ player.mandoDeJogo }}</p>
              </div>
            </div>
            <div v-if="isGeralScouts" class="column is-one-quarter">
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ player.partidas[player.mandoDeJogo] }}
                </p>
                <p class="player-field-name">Jogos {{ player.mandoDeJogo }}</p>
              </div>
            </div>
            <div v-if="isGeralScouts" class="column is-one-quarter">
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ player.media.total | decimals }}
                </p>
                <p class="player-field-name">Média</p>
              </div>
            </div>
            <div v-if="isGeralScouts" class="column is-one-quarter">
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ player.partidas.total }}
                </p>
                <p class="player-field-name">Jogos</p>
              </div>
            </div>
            <!-- <div v-if="isGeralScouts" class="column is-one-quarter">
              <div class="player-field-column">
                <p class="player-field-value">
                  C$ {{ player.preco | decimals }}
                </p>
                <p class="player-field-name">Preço</p>
              </div>
            </div> -->
            <div v-if="isGeralScouts" class="column is-one-quarter">
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ player.mandoDeJogo | uppercase }}
                </p>
                <p class="player-field-name">Mando</p>
              </div>
            </div>
            <div
              v-if="isGeralScouts && isBrazilChampionship"
              class="column is-one-quarter"
            >
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ player.valorizacaoMinima | decimals }}
                </p>
                <p class="player-field-name">Val. Mín.</p>
              </div>
            </div>
            <div
              v-if="isGeralScouts && isBrazilChampionship"
              class="column is-one-quarter"
            >
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ player.valorizacao | decimals }}
                </p>
                <p class="player-field-name">Val.</p>
              </div>
            </div>
            <div
              v-for="scout of player.scouts.total"
              :key="scout.id"
              class="column"
              :class="{
                'is-stats': !isGeralScouts,
                'is-one-quarter': isGeralScouts,
              }"
              v-show="!hideScoutsGeneral"
            >
              <div class="player-field-column">
                <p class="player-field-value">
                  {{ scout.valor }}
                </p>
                <p class="player-field-name">{{ scout.abreviacao }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

export default {
  name: "PlayerColumnMobile",
  mixins: [championshipMixins],
  props: {
    player: {
      type: Object,
      required: true,
    },
    groupScouts: {
      type: Number,
      required: true,
    },
  },
  computed: {
    hasIconStatus() {
      const status = ["provável", "contundido", "suspenso", "dúvida"];
      return status.includes(this.player.status.toLowerCase());
    },
    isGeralScouts() {
      return this.groupScouts === 1;
    },
    hideScoutsGeneral() {
      if (this.isBrazilChampionship) {
        return this.isGeralScouts;
      } else {
        return false;
      }
    },
  },
  methods: {
    getClubPlayer(player, mando) {
      if (mando === "casa") {
        return {
          src: player.time_escudo,
          alt: player.time_nome,
        };
      } else {
        return {
          src: player.time_vis_escudo,
          alt: player.time_vis_nome,
        };
      }
    },
    getStatusPlayer() {
      const staus = this.player.status.toLowerCase();
      if (staus === "provável") return "/svg/provavel.svg";
      if (staus === "contundido") return "/svg/contundido.svg";
      if (staus === "suspenso") return "/svg/suspenso.svg";
      if (staus === "dúvida") return "/svg/duvida.svg";
    },
    formatterName(name) {
      const nameSplitted = name.split(" ");
      if (nameSplitted[1]) {
        return `${nameSplitted[0][0]}. ${nameSplitted[1]}`;
      } else {
        return name;
      }
    },
    getTypeByValue(value) {
      return value < 0 ? "is-negative" : "is-positive";
    },
  },
};
</script>

<style lang="scss" scoped>
.player-column {
  text-align: left !important;
  position: relative;
  min-height: 65px;
  display: table;
  width: 100%;

  .player-team-shield {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 2px;
    top: 2px;
    z-index: 3;

    img {
      width: 15px;
    }
  }

  .player-column-status {
    position: absolute;
    width: 10px;
    height: 10px;
    left: -15px;
    top: 5px;
  }

  #player-columns-scouts {
    padding: 0 0 0 0.5em !important;
    width: 70%;
    .column {
      padding: 0.15em !important;
    }

    .is-stats {
      min-width: 16.6666%;
      max-width: 16.6666%;
    }
  }

  .name-clubs {
    font-size: 0.7em;
    padding: 0 0.25em;
  }
  .player-image {
    height: 65px;
    width: 60px;
    position: relative;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      left: 50%;
      transform: translate(-45%, 0%);
      bottom: 0;
      height: 55px;
    }
  }

  .player-container-info {
    position: absolute;
    width: 76%;
    right: 0;
    top: 0;
    bottom: 0;

    .about-player {
      height: 35px;
    }

    .player-title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.65em !important;
      line-height: 1.3em;
    }
    .player-subtitle {
      font-size: 0.5em !important;
      opacity: 0.8;
      text-transform: uppercase;

      span {
        font-weight: bold;
      }
    }

    .player-field-value-2 {
      font-weight: bold;
      font-size: 0.7em;
    }

    .player-field-column {
      min-width: 15px;

      .player-field-value {
        font-weight: bold;
        font-size: 0.7em;
      }

      .player-field-name {
        font-weight: normal;
        font-size: 0.5em;
        opacity: 0.5;
        text-transform: uppercase;
      }
    }
  }

  .player-match {
    width: 45px;
    .match-column {
      margin-bottom: 0 !important;
      padding: 0.25em 0 !important;
      display: flex;

      .level-item:not(:last-child) {
        margin-right: 0 !important;
        color: rgb(126, 126, 126);
      }

      img {
        width: 13px;
      }
    }
  }
}
</style>
