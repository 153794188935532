import httpStats from "@/services/httpStats.js";
import http from "@/services/http.js";

export default class ConfigureService {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.apiStats = httpStats;
    this.api = http;
  }

  get({ region, championship }) {
    return this.apiStats.get(`${region}/${championship}/${this.endpoint}`);
  }

  getById({ region, championship }, id) {
    return this.apiStats.get(
      `${region}/${championship}/${this.endpoint}/${id}`
    );
  }
}
