<template>
  <BaseLayout
    :championships="championships"
    :loading="loading"
    @change-championship="handleChangeChampionship"
    @click="handlerEveryClick"
  >
    <BasePage id="overview" title="Análises de recorrência">
      <div class="columns is-multiline">
        <div
          v-for="(analysis, index) of recurrences"
          :key="`analysis-${index}`"
          class="column is-full"
        >
          <Analysis :analysis="analysis" type="recurrence"></Analysis>
        </div>
      </div>
    </BasePage>
    <ModalNPSVue
      ref="nps"
      name="NPS Tela Recorrência"
      text="Está gostando da tela de Recorrência?"
    ></ModalNPSVue>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";
import Analysis from "../../components/Overview/Analysis.vue";
import ModalNPSVue from "../../components/ModalNPS.vue";
import Amplitude from "../../../../services/amplitude";

import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";
import OverviewService from "../../services/overviewService";
import { mapGetters } from "vuex";

const overviewService = new OverviewService();

export default {
  name: "Recurrence",
  mixins: [championshipMixins],
  components: {
    BasePage,
    BaseLayout,
    Analysis,
    ModalNPSVue,
  },
  data() {
    return {
      loading: true,
      recurrences: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    amplitudeInstance() {
      const { id, email, nome, plano, expirado, limitAccess } = this.userInfo;
      return new Amplitude({ id, email, nome, plano, expirado, limitAccess });
    },
  },
  methods: {
    fetchRecurrence() {
      this.loading = true;
      overviewService
        .getRecurrence({
          region: this.getChampionship.regiao.id,
          championship: this.getChampionship.id,
        })
        .then((response) => {
          this.recurrences = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleChangeChampionship() {
      this.fetchRecurrence();
    },
    handlerEveryClick() {
      const isValidated = localStorage.getItem("NPS Tela Recorrência");
      if (!isValidated) {
        this.$refs.nps.handlerEveryClick();
      }
    },
  },
  async created() {
    await this.loadChampionships();
    this.fetchRecurrence();
  },
};
</script>

<style></style>
