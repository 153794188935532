import ConfigureService from "@/services/configureService";

export default class AthleteService extends ConfigureService {
  constructor() {
    super("atletas");
  }

  searchAthletes({ region, championship }, query) {
    return this.apiStats.get(`${region}/${championship}/atletas?${query}`);
  }

  getScoutsGroups() {
    return this.apiStats.get(`/grupo-scouts`);
  }
}
