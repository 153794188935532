<template>
  <div id="overview-analysis">
    <h2>
      {{ analysis.descricao }}
      <b-tag :type="`is-position-${analysis.posicao}`">{{
        analysis.posicao.toUpperCase()
      }}</b-tag>
    </h2>
    <div class="columns is-multiline">
      <div
        v-for="{ label, field, template, clubs } of filters"
        :key="field"
        :class="`column ${template}`"
      >
        <h3>{{ label }}</h3>

        <div :class="`wrapper wp-${field}`">
          <div
            v-for="(club, index) of clubs"
            :key="`clubs-analysis-${club.id}`"
            :class="`club_box box-${index}-${field}`"
          >
            <div class="club-box-column">
              <div class="club-box-row">
                <img :src="club.escudo" />
              </div>
              <p class="club-box-name">{{ club.abreviacao }}</p>
            </div>
            <div class="club-box-column">
              <div class="club-box-row">
                <span class="club-box-values-scout">{{ club.valor }}</span>
              </div>
              <p class="club-box-name">{{ analysis.scout }}</p>
            </div>
            <div v-if="type === 'recurrence'" class="club-box-column">
              <div class="chart">
                <DonutChartVue
                  :sections="[
                    {
                      value: club.porcentagem,
                      color: handleColorByValue(club.porcentagem),
                    },
                  ]"
                  :size="getPropertiesChart(field, index).size"
                  :background="getPropertiesChart(field, index).background"
                  foreground="#6a788f"
                  :thickness="25"
                  :description="`${club.porcentagem}%`"
                ></DonutChartVue>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DonutChartVue from "../../../../components/helpers/charts/DonutChart.vue";

export default {
  name: "Analysis",
  components: {
    DonutChartVue,
  },
  props: {
    analysis: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "overview",
    },
  },
  data() {
    return {
      filters: [
        {
          label: "Últimos 5 jogos seguidos",
          field: "ultimos_5",
          maxClubs: 7,
          template: "is-auto-fullhd is-three-fifths-desktop is-full",
          clubs: [],
        },
        {
          label: "Mandantes - Últimos 3 jogos em casa",
          field: "ultimos_3_mandante",
          maxClubs: 3,
          template: "is-one-fifth-fullhd is-narrow-desktop is-half",
          clubs: [],
        },
        {
          label: "Visitantes - Últimos 3 jogos fora",
          field: "ultimos_3_visitante",
          maxClubs: 3,
          template: "is-one-fifth-fullhd is-narrow-desktop is-half",
          clubs: [],
        },
      ],
      dataChart: [{ label: "Teste", value: 80, color: "#00f0f8" }],
    };
  },
  methods: {
    getPropertiesChart(field, index) {
      const isFirst = index === 0;
      const isRelevantPosition = index > 0 && index < 3;

      const size = this.handleSizePropertie(field, isFirst, isRelevantPosition);
      const background = this.handleBackgroundPropertie(
        field,
        isFirst,
        isRelevantPosition
      );

      return {
        background,
        size: this.handleMobileNumber(size),
      };
    },
    handleColorByValue(value) {
      if (value <= 30) return "#e73872";
      else if (value > 30 && value <= 60) return "#ecc5a8";
      else if (value > 60 && value <= 80) return "#dfeca8";
      else if (value > 80) return "#00f0f8";
      else "#a8cbec";
    },
    handleMobileNumber(number) {
      if (!this.isMobile) return number;
      const resolutionMultiple = window.screen.width / 648;
      return number * (resolutionMultiple * 1.5);
    },
    handleSizePropertie(field, isFirst, isRelevantPosition) {
      const sizeInMobile = this.isMobile ? 55 : 70;
      const sizeByRanking = isFirst
        ? 90
        : isRelevantPosition
        ? sizeInMobile
        : 50;
      return field === "ultimos_5" ? sizeByRanking : 50;
    },
    handleBackgroundPropertie(field, isFirst, isRelevantPosition) {
      const backgroundByRanking = isFirst
        ? "#4c5377"
        : isRelevantPosition
        ? "#3f435e"
        : "#2f3248";
      return field === "ultimos_5" ? backgroundByRanking : "#3f435e";
    },
  },
  created() {
    this.filters.map((filter) => {
      const clubsFilteredAndOrdered = this.analysis[filter.field].sort(
        (a, b) => b.valor > a.valor
      );

      filter.clubs = clubsFilteredAndOrdered.filter(
        (c, i) => i < filter.maxClubs
      );
      return filter;
    });
  },
};
</script>

<style lang="scss">
#overview-analysis {
  width: 100%;
  margin-bottom: 1.5rem;

  h2 {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 0.75em;

    .tag {
      margin-left: 1em;
    }

    @media (max-width: 766px) {
      font-size: 1em;
    }
  }

  h3 {
    display: flex;
    align-items: flex-end;
    height: 3em;
    font-weight: bold;
    margin-bottom: 0.5em;

    @media (max-width: 766px) {
      font-size: 0.9em;
    }
  }

  .wp-ultimos_3_mandante,
  .wp-ultimos_3_visitante {
    grid-template-columns: 1fr !important;
  }

  .wrapper {
    background-color: $blue-dark-2;
    border-radius: 4px;
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(80px, auto);

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: minmax(70px, auto);
    }

    .club_box {
      padding: 0.5em 1em;
      background-color: $blue-dark-1;
      border-radius: 4px;
      margin: 0.25em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .club-box-column {
        display: flex;
        flex-direction: column;
        align-items: center;

        .club-box-row {
          height: 30px;

          img {
            height: 30px;
          }
        }

        .club-box-name {
          margin-top: 0.5em;
          font-size: 0.9em;
        }

        .club-box-values-scout {
          font-size: 2em;
          font-weight: bolder;
        }

        @media (max-width: 768px) {
          .club-box-row {
            img {
              height: 20px;
            }
          }

          .club-box-name {
            font-size: 0.75em;
          }
        }
      }
    }

    .box-0-ultimos_5 {
      grid-column-start: 1;
      grid-column-end: 3;
      height: 155px;
      background-color: #4c5377;

      .club-box-row {
        height: 90px !important;

        img {
          height: 90px !important;
        }
      }
      .club-box-name {
        font-size: 1.1em !important;
      }
      .club-box-values-scout {
        font-size: 5em !important;
        font-weight: bolder;
      }

      @media (max-width: 768px) {
        height: 100px;

        .club-box-row {
          height: 60px !important;

          img {
            height: 60px !important;
          }
        }

        .club-box-values-scout {
          font-size: 3.5em !important;
          font-weight: bolder;
        }
      }
    }

    .box-1-ultimos_5,
    .box-2-ultimos_5 {
      .club-box-row {
        height: 70px !important;

        img {
          height: 70px !important;
        }
      }

      .club-box-values-scout {
        font-size: 4em !important;
        font-weight: bolder;
      }

      @media (max-width: 768px) {
        .club-box-row {
          height: 30px !important;

          img {
            height: 30px !important;
          }
        }

        .club-box-values-scout {
          font-size: 2em !important;
          font-weight: bolder;
        }
      }
    }

    .box-3-ultimos_5,
    .box-4-ultimos_5,
    .box-5-ultimos_5,
    .box-6-ultimos_5 {
      background-color: #2f3248;
    }

    .box-1-ultimos_5,
    .box-2-ultimos_5 {
      .grid-values {
        flex-direction: column;
        align-items: flex-end;
        .values {
          margin-bottom: 1em;
        }
      }
    }

    @media (max-width: 768px) {
      .box-1-ultimos_5,
      .box-2-ultimos_5 {
        grid-column-start: 1;
        grid-column-end: 3;
        height: 60px;

        .grid-values {
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .grid-club-info {
      display: flex;

      span {
        font-size: 1.2em;
        font-weight: bolder;
        margin-right: 0.5em;
      }
    }

    .grid-values {
      display: flex;
      align-items: right;
      width: fit-content;
      text-align: right;

      .chart {
        padding: 0 0.5em;
      }

      .values {
        display: flex;
        width: 40px;
        flex-direction: column;
        align-items: center;

        span {
          font-size: 1.5em;
          font-weight: bold;
          text-align: right;
        }

        p {
          text-align: right;
          font-size: 0.9em;
        }
      }

      @media (max-width: 768px) {
        .values {
          span {
            font-size: 1.1em;
          }
          p {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
</style>
