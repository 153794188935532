<template>
  <div class="card">
    <b-loading :active.sync="loadingAnalysis" :is-full-page="false"></b-loading>
    <div v-if="!loadingAnalysis" class="card-content">
      <div class="card-header-2">
        <div class="level is-mobile" style="margin-bottom: 5px">
          <div class="level-left">
            <div>
              <b-tag :type="`is-position-${getPositionLabel().abreviacao}`">{{
                getPositionLabel().descricao.toUpperCase()
              }}</b-tag>
            </div>
          </div>
          <div class="level-right buttons">
            <div class="columns is-variable is-1 is-mobile">
              <div class="column">
                <b-button
                  outlined
                  icon-right="pencil"
                  size="is-small"
                  type="is-pigeon"
                  @click="$emit('update')"
                ></b-button>
              </div>
              <div class="column">
                <b-button
                  outlined
                  type="is-danger"
                  icon-right="close"
                  size="is-small"
                  @click="remove"
                ></b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="level is-mobile" style="margin-bottom: 0px">
          <div class="level-left">
            <p class="card-subtitle">
              {{ getTitle() }}
            </p>
            <span class="marker">•</span>
            <p class="card-subtitle">{{ getMatchesFilter() }}</p>
          </div>
        </div>
        <p
          class="card-subtitle"
          style="margin-bottom: 1em; font-weight: normal"
        >
          {{
            scoutCard.mandoDeJogo === "ambos"
              ? "Todos os mandos"
              : "De acordo com o mando atual"
          }}
        </p>
      </div>
      <div v-if="!loadingAnalysis" class="card-body">
        <b-tabs size="is-small" expanded>
          <b-tab-item label="Mandantes">
            <TableScouts
              :data="analysis.home"
              :metrics="metrics.home"
              :type-card="scoutCard.tipo"
              state="home"
              :resume="modelViewResumed"
              @click-row="handleClickTable($event, 'home')"
            ></TableScouts>
          </b-tab-item>
          <b-tab-item label="Visitantes">
            <TableScouts
              :data="analysis.away"
              :metrics="metrics.away"
              :type-card="scoutCard.tipo"
              state="away"
              :resume="modelViewResumed"
              @click-row="handleClickTable($event, 'away')"
            ></TableScouts>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";
import TableScouts from "./Table.vue";
import ScoutsAnalsysisServices from "@/apps/Panel/services/scoutsAnalysisService";
import qs from "qs";

const scoutsAnalysisService = new ScoutsAnalsysisServices();

export default {
  name: "ScoutCard",
  components: {
    TableScouts,
  },
  mixins: [championshipMixins],
  props: {
    viewResumed: {
      type: Boolean,
      default: false,
    },
    scoutCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      analysis: [],
      loadingAnalysis: true,
      metrics: { away: {}, home: {} },
      modelViewResumed: this.viewResumed,
    };
  },
  watch: {
    viewResumed(newValue) {
      this.modelViewResumed = newValue;
    },
  },
  methods: {
    fetchAnalysis() {
      this.loadingAnalysis = true;

      const position = this.getPosition();
      const scout = this.getScout();
      const params = qs.stringify({
        tipo: this.scoutCard.tipo,
        posicao: position,
        scout,
        mandoDeJogo: this.scoutCard.mandoDeJogo,
        jogos: this.scoutCard.jogos,
      });

      scoutsAnalysisService
        .getAnalysis(
          {
            region: this.getChampionship.regiao.id,
            championship: this.getChampionship.id,
          },
          params
        )
        .then((analysis) => {
          this.analysis = this.handleAnalysis(analysis);
        })
        .finally(() => {
          this.loadingAnalysis = false;
        });
    },
    handleAnalysis(analysis) {
      const awayAnalsysis = [];
      const homeAnalsysis = [];

      analysis.forEach((match) => {
        const diffHome = match.mandante.conquistado - match.visitante.cedido;
        const diffAway = match.visitante.conquistado - match.mandante.cedido;

        awayAnalsysis.push({ diff: diffAway, ...match });
        homeAnalsysis.push({ diff: diffHome, ...match });
      });

      this.handleMetrics(awayAnalsysis, "away");
      this.handleMetrics(homeAnalsysis, "home");
      return { away: awayAnalsysis, home: homeAnalsysis };
    },
    handleMetrics(array, state) {
      const analysis = [...array];
      const {
        highestValueCon,
        lowestValueCon,
        highestValueCed,
        lowestValueCed,
      } = this.getMetrics(analysis, state);

      this.metrics[state] = {
        highestValueCon,
        lowestValueCon,
        highestValueCed,
        lowestValueCed,
      };
    },
    getMetrics(analysis, state) {
      const conquistado = state === "home" ? "mandante" : "visitante";
      const cedido = state === "home" ? "visitante" : "mandante";

      const sortCon = analysis
        .map((a) => a[conquistado].conquistado)
        .sort((a, b) => b - a);
      const sortCed = analysis
        .map((a) => a[cedido].cedido)
        .sort((a, b) => b - a);

      const highestValueCon = sortCon[0];
      const lowestValueCon = sortCon[sortCon.length - 1];
      const highestValueCed = sortCed[0];
      const lowestValueCed = sortCed[sortCed.length - 1];

      return {
        highestValueCon,
        lowestValueCon,
        highestValueCed,
        lowestValueCed,
      };
    },
    remove() {
      this.$buefy.dialog.confirm({
        title: "Remover análise",
        message: "Você tem certeza que deseja <b>excluir</b> esta análise?",
        confirmText: "Remover análise",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.$emit("remove"),
      });
    },
    getPositionLabel() {
      if (this.scoutCard.posicao.abreviacao) {
        return this.scoutCard.posicao;
      } else {
        return {
          abreviacao: this.scoutCard.posicao,
          descricao: this.scoutCard.posicao,
        };
      }
    },
    getMatchesFilter() {
      const { jogos } = this.scoutCard;

      if (jogos == "ult_3") return "últimos 3 jogos";
      if (jogos == "ult_5") return "últimos 5 jogos";
      if (jogos == "fase_1") return "primeiro turno";
      if (jogos == "fase_2") return "segundo turno";
      if (jogos == "todos") return "todos os jogos";
      return "";
    },
    getTitle() {
      if (this.scoutCard.scout)
        return `Análise de ${this.scoutCard.scout.ds_scout}`;
      else {
        return "Média de Pontuação";
      }
    },
    getScout() {
      return this.scoutCard.scout && typeof this.scoutCard.scout === "object"
        ? this.scoutCard.scout.cd_scout
        : this.scoutCard.scout;
    },
    getPosition() {
      return typeof this.scoutCard.posicao === "object"
        ? this.scoutCard.posicao.abreviacao
        : this.scoutCard.posicao;
    },
    handleClickTable(data, state) {
      this.$emit("open-details-card", { ...data, state });
    },
  },
  created() {
    this.fetchAnalysis();
  },
};
</script>

<style lang="scss">
.card {
  background-color: $blue-dark-1;

  .card-content {
    .card-header-2 {
      position: relative;

      .card-subtitle {
        font-size: 0.8em;
        line-height: 0.8em;
        margin-top: 0.5em;
        font-weight: bold;
      }

      .marker {
        line-height: 0.1em;
        margin: 0 0.5em !important;
      }
    }
    .card-body {
      .card-centralize {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .b-tabs .tab-content {
        padding: 0.25em 0 !important;
      }
      .b-tabs:not(:last-child) {
        margin-bottom: 0 !important;
      }

      .tabs {
        ul {
          li.is-active a {
            color: $primary !important;
            border-bottom-color: $primary !important;
          }
        }

        a {
          color: #7888a7 !important;
          border-bottom-color: #7888a7;
        }
      }

      .td-percent-100 {
        background-color: rgba(31, 155, 0, 0.75) !important;
      }
      .td-percent-75 {
        background-color: rgba(96, 187, 73, 0.75) !important;
      }
      .td-percent-50 {
        background-color: rgba(184, 201, 71, 0.75) !important;
      }
      .td-percent-25 {
        background-color: rgba(221, 122, 122, 0.75) !important;
      }
      .td-lowest {
        background-color: rgba(221, 48, 48, 0.75) !important;
      }
      .td-regular {
        background-color: rgba(238, 200, 130, 0.75) !important;
      }
    }
  }
}
</style>
