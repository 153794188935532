<template>
  <div class="app-panel-container" @click="$emit('click', $event)">
    <Navbar
      :championships="championships"
      :is-page-for-default-championship="isPageForDefaultChampionship"
      @change-championship="handleChangeChampionship"
    ></Navbar>

    <div class="app-panel-content">
      <div class="app-panel-alignment">
        <b-loading :active.sync="loading"></b-loading>
        <section v-if="!loading" class="section adjust-section">
          <b-message
            class="message-default-championship"
            :active.sync="isPageForDefaultChampionship"
            type="is-independece"
          >
            <p>
              Os dados informados nesta tela são apenas do
              <b>campeonato brasileiro</b>.
            </p>
          </b-message>

          <slot></slot>
        </section>
      </div>
    </div>

    <b-notification
      class="notificao-limited-access"
      type="is-independence"
      :active.sync="isActiveLimitedAccess"
      aria-close-label="Close notification"
    >
      Sua Assinatura possui um acesso limitado, sem permissão para algumas
      telas, ou expirando antes do final da temporada.
      <router-link :to="'/renewSignature/' + userId + '?action=upgrade'">
        <b class="has-text-primary">Clique Aqui</b>
      </router-link>
      para atualizar a Assinatura!
    </b-notification>

    <footer>
      <div class="app-panel-alignment">
        <div class="level">
          <div class="level-left">
            <img
              class="footer-brand"
              src="/images/escala10.png"
              alt="Escala 10"
            />
            <span>© Copyright 2019 - 2022 </span>
          </div>
          <div class="level-right group-social-medias">
            <a
              href="https://t.me/escala10"
              v-tooltip.bottom="{
                content: 'Telegram',
                classes: 'home-tooltip',
              }"
              target="_blank"
            >
              <b-icon pack="fab" icon="telegram-plane"></b-icon>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5521974399122"
              v-tooltip.bottom="{
                content: 'Whatsapp',
                classes: 'home-tooltip',
              }"
              target="_blank"
            >
              <b-icon pack="fab" icon="whatsapp"></b-icon>
            </a>
            <a
              href="https://www.facebook.com/escala10.br/"
              v-tooltip.bottom="{
                content: 'Facebook',
                classes: 'home-tooltip',
              }"
              target="_blank"
            >
              <b-icon pack="fab" icon="facebook"></b-icon>
            </a>
            <a
              href="https://www.instagram.com/escala10.br/"
              v-tooltip.bottom="{
                content: 'Instagram',
                classes: 'home-tooltip',
              }"
              target="_blank"
            >
              <b-icon pack="fab" icon="instagram"></b-icon>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Amplitude from "../../../services/amplitude";
import Navbar from "../components/Navbar.vue";

export default {
  name: "BaseLayout",
  components: {
    Navbar,
  },
  props: {
    championships: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isPageForDefaultChampionship: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActiveLimitedAccess: false,
    };
  },
  computed: {
    ...mapGetters("Championships", ["getChampionship"]),
    ...mapGetters(["userId", "userInfo"]),
    limitedAccess() {
      return this.$accessControl.checkLimitAccess();
    },
    amplitudeInstance() {
      const { id, email, nome, plano, expirado, limitAccess } = this.userInfo;
      return new Amplitude({ id, email, nome, plano, expirado, limitAccess });
    },
  },
  mounted() {
    if (this.limitedAccess) this.isActiveLimitedAccess = true;
  },
  methods: {
    handleChangeChampionship() {
      this.$emit("change-championship");

      this.amplitudeInstance.createEvent("changeChampionship", {
        championshipName: this.getChampionship.descricao,
        championshipId: this.getChampionship.id,
      });
    },
  },
};
</script>

<style lang="scss">
.app-panel-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  background-color: #1f213179;
  position: relative;

  .notificao-limited-access {
    position: fixed;
    left: 2rem;
    bottom: 2rem;
    width: 40%;
    z-index: 30;

    a {
      text-decoration: none !important;

      &:hover {
        border-bottom: 1px solid #00f0f8;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .app-panel-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: table;
    padding-bottom: 1rem;

    .navbar {
      z-index: 29 !important;
    }
  }

  .app-panel-alignment {
    height: 100%;
    width: 90%;
    margin: 0 auto;

    @media (max-width: 1024px) and (min-width: 769px) {
      width: 95% !important;
    }

    @media (max-width: 768px) {
      width: calc(100% - 1em) !important;

      .adjust-section {
        padding: 1rem 0em !important;
      }
    }

    .adjust-section {
      padding: 2rem 0 0 1em;
    }
  }

  footer {
    position: relative;
    width: 100%;
    padding: 2em 0;
    background-color: #1b1c25;
    color: #00f0f8;

    .level-left {
      align-items: center;
    }

    .footer-brand {
      height: 30px;
      margin-right: 1em;
    }

    .group-social-medias {
      font-size: 1.5em;

      .icon {
        margin: 0 0.5em;
      }
    }

    @media (max-width: 768px) {
      .level {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .footer-brand {
        height: 25px;
      }

      .group-social-medias {
        font-size: 1em;
      }
    }
  }
}
</style>
