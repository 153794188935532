<template>
  <BaseLayout
    :championships="championships"
    :loading="loading"
    @change-championship="handleChangeChampionship"
    @click="handlerEveryClick"
  >
    <BasePage id="scout-analysis" title="Cruzamento de Scouts">
      <template slot="options">
        <div class="ajust-switch-options">
          <b-switch
            v-model="viewResumed"
            size="is-small"
            type="is-independence"
            @input="handleResumeView"
          >
            Ver tabelas resumidas
          </b-switch>
        </div>
        <div>
          <b-button
            :expanded="isMobile"
            :disabled="limitedCards"
            type="is-independence"
            @click="openModalAnalysis"
          >
            Criar nova Análise
          </b-button>
        </div>
      </template>

      <div v-if="!loading && cards.length > 0" class="columns is-multiline">
        <div
          v-for="card of cards"
          :key="card.id"
          class="
          column
          is-one-quarter
          is-one-quarter-widescreen
          is-one-third-desktop
          is-half-tablet
        "
        >
          <ScoutCard
            :scout-card="card"
            :view-resumed="viewResumed"
            @update="updateCard(card)"
            @remove="removeCard(card)"
            @open-details-card="openDetailsCard(card, $event)"
          ></ScoutCard>
        </div>
      </div>
      <div v-else-if="!loading && cards.length === 0">
        <section class="section">
          <div class="content has-text-independence has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Não há análises criadas</p>
            <b-button
              :disabled="limitedCards"
              type="is-independence"
              @click="openModalAnalysis"
            >
              Criar Análise
            </b-button>
          </div>
        </section>
      </div>
    </BasePage>
    <ModalNPSVue
      ref="nps"
      name="NPS Tela Cruzamento de Scouts"
      text="Está gostando do Cruzamento de Scouts?"
    ></ModalNPSVue>
  </BaseLayout>
</template>

<script>
import { mapGetters } from "vuex";

import notifyMixin from "@/_mixins/notify";
import ScoutsAnalysisService from "@/apps/Panel/services/scoutsAnalysisService";
import CommonService from "@/apps/Panel/services/commonService";
import Amplitude from "../../../../services/amplitude";

import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";
import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";
import ScoutCard from "@/apps/Panel/components/scoutsAnalysis/Card.vue";
import ModalEditAnalysis from "@/apps/Panel/components/scoutsAnalysis/ModalEdit.vue";
import ModalDetailsAnalysis from "@/apps/Panel/components/scoutsAnalysis/ModalDetails.vue";
import ModalNPSVue from "../../components/ModalNPS.vue";

const scoutsAnalysisService = new ScoutsAnalysisService();
const commonService = new CommonService();

export default {
  name: "ScoutsAnalysis",
  components: {
    BaseLayout,
    BasePage,
    ScoutCard,
    ModalEditAnalysis,
    ModalDetailsAnalysis,
    ModalNPSVue,
  },
  mixins: [championshipMixins, notifyMixin],
  data() {
    return {
      viewResumed: false,
      loading: true,
      cards: [],
      scouts: [],
      positions: [],
      allPositions: true,
      config: null,
      modalAnalysisActive: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    limitedCards() {
      return this.cards.length < 20 ? false : true;
    },
    amplitudeInstance() {
      const { id, email, nome, plano, expirado, limitAccess } = this.userInfo;
      return new Amplitude({ id, email, nome, plano, expirado, limitAccess });
    },
  },
  methods: {
    handleChangeChampionship() {
      this.loadData();
    },
    async result(cardConfig) {
      this.loading = true;
      try {
        const config = {
          ...cardConfig,
        };

        if (config.id) {
          await scoutsAnalysisService.updateCard(
            {
              region: this.getChampionship.regiao.id,
              championship: this.getChampionship.id,
            },
            this.userInfo.id,
            config
          );
          this.notify("Análise atualizada com sucesso!", "is-success");
        } else {
          await scoutsAnalysisService.createCard(
            {
              region: this.getChampionship.regiao.id,
              championship: this.getChampionship.id,
            },
            this.userInfo.id,
            config
          );
          this.notify("Nova análise criada com sucesso!", "is-success");
        }

        const cardsResponse = await this.fetchCards();
        const cards = this.normalizeCards(
          cardsResponse,
          this.positions,
          this.scouts
        );
        this.cards = cards;
      } catch {
        this.notify(
          "Houve um problema ao salvar sua análise, tente novamente!",
          "is-danger"
        );
      }
      this.loading = false;
    },
    async removeCard(card) {
      this.loading = true;
      try {
        await scoutsAnalysisService.deleteCard(
          {
            region: this.getChampionship.regiao.id,
            championship: this.getChampionship.id,
          },
          this.userInfo.id,
          card.id
        );
        this.notify("Análise excluída", "is-success");

        const cardsResponse = await this.fetchCards();
        const cards = this.normalizeCards(
          cardsResponse,
          this.positions,
          this.scouts
        );
        this.cards = cards;
      } catch {
        this.notify(
          "Houve um problema ao exlcuir sua análise, tente novamente!",
          "is-danger"
        );
      }
      this.loading = false;
    },
    updateCard(card) {
      const posicao =
        typeof card.posicao === "object"
          ? card.posicao.abreviacao
          : card.posicao;
      const scout =
        card.scout && typeof card.scout === "object"
          ? card.scout.cd_scout
          : card.scout;

      this.config = {
        id: card.id,
        tipo: card.tipo,
        jogos: card.jogos,
        mandoDeJogo: card.mandoDeJogo,
        scout,
        posicao,
      };
      this.openModalAnalysis();
      this.config = null;
    },
    fetchCards() {
      return scoutsAnalysisService.getCards(
        {
          region: this.getChampionship.regiao.id,
          championship: this.getChampionship.id,
        },
        this.userInfo?.id
      );
    },
    fetchScouts() {
      return commonService.getScouts();
    },
    fetchPositions() {
      return commonService.getPositions({
        region: this.getChampionship.regiao.id,
        championship: this.getChampionship.id,
      });
    },
    normalizeCards(cards, positions, scouts) {
      return cards.map((card) => {
        if (card.posicao !== "todos") {
          card.posicao = positions.find((p) => p.abreviacao === card.posicao);
        }
        if (card.scout) {
          card.scout = scouts.find((s) => s.cd_scout === card.scout);
        }

        return card;
      });
    },
    openModalAnalysis() {
      if (this.limitedCards) return;

      this.$buefy.modal.open({
        parent: this,
        component: ModalEditAnalysis,
        hasModalCard: true,
        canCancel: false,
        props: {
          allPositions: this.allPositions,
          positions: this.positions,
          scouts: this.scouts,
          config: this.config,
        },
        events: {
          result: this.result,
          close: () => {
            this.config = null;
          },
        },
      });
    },
    handleResumeView(bool) {
      localStorage.setItem("analysis_scouts_view_resumed", bool);
    },
    getViewResumed() {
      const hasViewResumed = localStorage.getItem(
        "analysis_scouts_view_resumed"
      );
      this.viewResumed = hasViewResumed === "true" ? true : false;
    },
    loadData() {
      this.loading = true;
      Promise.all([
        this.fetchScouts(),
        this.fetchPositions(),
        this.fetchCards(),
      ])
        .then((responses) => {
          const [scoutsResponse, positionsResponse, cardsResponse] = responses;
          this.scouts = scoutsResponse.data;
          this.positions = positionsResponse;

          // let permittedPositions = this.positions.filter(
          //   (p) =>
          //     this.$accessControl.checkPermission(`scouts.${p.sigla}`) &&
          //     this.$accessControl.checkStatus(`scouts.${p.sigla}`)
          // );
          this.allPositions = true; // permittedPositions.length == this.positions.length;
          // this.positions = permittedPositions;

          const cards = this.normalizeCards(
            cardsResponse,
            positionsResponse,
            scoutsResponse.data
          );
          this.cards = cards;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDetailsCard(card, match) {
      const { data, choose, state } = match;
      let apuracao;

      if (state === "home") {
        apuracao = choose === "mandante" ? "conquistado" : "cedido";
      } else if (state === "away") {
        apuracao = choose === "mandante" ? "cedido" : "conquistado";
      }

      const params = {
        ...card,
        apuracao,
        clube: data[choose].id,
        clubeMandoDeJogo: choose,
      };

      this.$buefy.modal.open({
        parent: this,
        component: ModalDetailsAnalysis,
        hasModalCard: true,
        canCancel: false,
        props: {
          params,
          actualMatch: match,
        },
      });
    },
    handlerEveryClick() {
      const isValidated = localStorage.getItem("NPS Tela Cruzamento de Scouts");
      if (!isValidated) {
        this.$refs.nps.handlerEveryClick();
      }
    },
  },
  async created() {
    await this.loadChampionships();
    this.getViewResumed();
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.ajust-switch-options {
  margin: 0.5em 1.2em;

  @media (max-width: 768px) {
    margin: 1em 0 !important;
  }
}
</style>
