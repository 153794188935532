<template>
  <div :id="id">
    <div class="level ajust-levels">
      <div class="level-left">
        <div>
          <h1 class="title" id="title">
            <router-link v-if="hasBack" :to="routeBack">
              <b-icon pack="fas" icon="arrow-left" size="is-small"></b-icon>
            </router-link>
            {{ title }}
          </h1>
          <h2 class="subtitle" v-if="hasSubtitle">
            {{ subtitle }}
          </h2>
        </div>
      </div>
      <div class="level-right">
        <slot name="options"></slot>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: "BasePage",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    hasBack: {
      type: Boolean,
      default: false,
    },
    routeBack: {
      type: String,
      default: "/painel",
    },
  },
  computed: {
    hasSubtitle() {
      return !!this.subtitle && this.$mq !== "sm";
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  margin-top: 0;

  .icon {
    margin-right: 1em;
  }
}
.ajust-levels {
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .ajust-levels {
    margin-bottom: 0.5em !important;
  }
  .ajust-levels .level-left {
    align-content: flex-start !important;
  }
  .ajust-levels .level-right {
    margin-top: 0.5em !important;
  }
  .atl-legend {
    padding: 7px 0 !important;
  }
  .compative-atl,
  .atl-legend h2 {
    font-size: 0.85rem !important;
  }
  .nome {
    font-size: 0.9rem !important;
  }
  .posicao {
    font-size: 0.7rem !important;
  }
  .align-scouts-atl {
    width: max-content !important;
    float: left;
    text-align: left !important;
    /* padding-right: 0.75rem; */
  }
  .align-scouts-jg {
    float: left;
  }
  .atl-info-cel {
    display: block;
  }
  .atl-info-cel label {
    font-size: 0.7rem;
    opacity: 0.6;
  }
  .atl-info-cel p {
    font-size: 0.8rem;
  }
}
.align-atl-clb {
  position: relative;
  width: 60px;
}
.align-scouts-atl {
  margin: 0 0 0 10px;
  /* width: 70%; */
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.align-scouts-jg {
  display: table;
  line-height: 1rem;
}
.align-scouts-jg p {
  display: inline-flex;
}
.group-opc-atl {
  padding: 10px 5px 5px 5px;
  width: 100%;
}
.group-opc-atl h3 {
  font-weight: bolder;
  margin-left: 10px;
  text-transform: uppercase;
}
.round-pos-atl {
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
}
.round-pos-atl-2 {
  padding: 6px;
  margin: 0 12px 0 0;
  border-radius: 50%;
}
.round-pos-atl-3 {
  height: 15px;
  width: 15px;
  margin: 0 12px 0 0;
  border-radius: 50%;
}
.group-opc-atl span {
  text-transform: uppercase;
  font-size: 0.75rem;
}
.padding-gap {
  padding: 0.5em 0.25em !important;
}

.nome-jogador {
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}
.sub-subtitle {
  opacity: 0.6;
  font-size: 0.7rem;
  margin: 0;
  line-height: 0.7rem;
}
@media screen and (min-width: 769px) and (max-width: 1390px) {
  .round-team-atl {
    height: 33px !important;
    width: 33px !important;
  }
  .round-team-atl img {
    width: 30px;
  }
  .round-team-atl-jogador-1 {
    height: 35px !important;
    width: 35px !important;
  }
  .round-team-atl-1 {
    height: 25px !important;
    width: 25px !important;
  }
  .round-team-atl-1 img {
    width: 22px;
  }
  .round-team-atl-jogador-1 img {
    position: relative;
    top: 50%;
    left: 50%;
    padding: 3px;
    transform: translate(-50%, -42%);
  }
  .numeric-table {
    font-size: 0.8rem;
    font-weight: bold;
  }
  .span-scout {
    font-size: 0.75rem !important;
  }
  .align-scouts-atl {
    /* width: 48% !important; */
  }
  /*
  .align-scouts-jg {
    max-width: 85%;
  } */
  .nome-jogador {
    font-size: 0.9rem !important;
  }
  .subtitle-atl {
    font-size: 0.7rem !important;
  }
  .compative-atl,
  /* .tabela-atl {
    height: 74vh !important;
  } */
  .align-bts-table {
    display: block !important;
    justify-content: center;
  }
  .legend-position {
    font-size: 0.6rem !important;
  }
}
.round-team-atl {
  /* background-color: white; */
  position: relative;
  border-radius: 50%;
  height: 42px;
  width: 42px;
}
.round-team-atl img {
  position: relative;
  top: 50%;
  left: 44%;
  padding: 3px;
  transform: translate(-50%, -50%);
}

.round-team-atl-jogador {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  overflow: hidden;
  float: left;
}
.round-team-atl-jogador img {
  position: relative;
  top: 50%;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -42%);
}
.round-team-atl-1 {
  background-color: white;
  position: relative;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  height: 30px;
  width: 30px;
  left: 49%;
  top: 10px;
  transform: translate(-10%, 66%);
}
.round-team-atl-jogador-1 {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  top: 10px;
  overflow: hidden;
  float: left;
}

.round-team-atl-1 img {
  position: relative;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -145%);
}
.round-team-atl-jogador-1 img {
  position: relative;
  top: 50%;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -42%);
}

@media screen and (max-width: 768px) {
  .round-team-atl-1 {
    background-color: white;
    position: relative;
    border-radius: 50%;
    border: 1px solid #4d4d4d;
    height: 25px;
    width: 25px;
    left: 49%;
    top: 15px;
    transform: translate(-10%, 66%);
  }
  .round-team-atl-jogador-1 {
    background-color: white;
    position: relative;
    border-radius: 50%;
    top: 10px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    float: left;
  }

  .round-team-atl-1 img {
    position: relative;
    left: 53%;
    padding: 3px;
    transform: translate(-50%, -168%);
  }
  .round-team-atl-jogador-1 img {
    position: relative;
    top: 50%;
    left: 50%;
    padding: 3px;
    transform: translate(-50%, -42%);
  }
  .round-jogador-acd {
    border: 1px solid #1f1f1f;
  }
  .round-jogador-acd img {
    position: relative;
    left: 50%;
    transform: translate(-50%, 10%);
  }
}

.jogador-campo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.span-scout {
  margin: 0 2px;
  font-size: 0.65rem;
  display: inline-flex;
}
.select-scout {
  font-weight: bold;
  transform: scale(1.1);
  margin: 5px 8px;
  height: auto;
}

.tabela-atl {
  width: 100%;
  border-radius: 7px;
  padding: 1em;
}

@media (max-width: 658px) {
  .tabela-atl {
    padding: 1em 0em !important;
  }
}

.subtitle-atl {
  opacity: 0.4;
  display: block;
}

.round-jogador-acd {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  overflow: hidden;
  z-index: 1;
}

.legenda-nome-acd {
  z-index: 2;
  position: relative;
  /* background-color: #202833; */
  background-color: #333333;
  color: white;
  padding: 5px 20px;
  border-radius: 20px;
  top: -10px;
  text-align: center;
  font-size: 0.9rem;
  /* width: fit-content; */
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.fit-content-acd {
  width: 100%;
  padding: 5px 5px 15px 5px;
  position: relative;
  /* background-color: #202833; */
  background-color: #333333;
  border-radius: 20px;
}
.fit-content-acd h5 {
  width: 100%;
  text-align: center;
  border-radius: 20px;
  border: 2px solid rgb(156, 156, 156);
  color: rgb(156, 156, 156);
  padding: 3px 0;
  margin-bottom: 15px;
}
.fit-content-acd-2 {
  width: 100%;
  padding: 5px;
}
.fit-content-acd-2 h3 {
  font-weight: 600;
  text-align: center;
}

.field-acd {
  text-align: center;
  margin: 5px 0;
}
.field-acd h6 {
  font-size: 0.75rem;
}
.field-acd p {
  opacity: 0.7;
  font-size: 0.75rem;
}

.col-acd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-inside-fit {
  padding: 5px;
  font-size: 0.75rem;
}
.content-inside-fit div {
  padding: 5px 0;
}

.graph-title {
  width: 100%;
  font-weight: bolder;
  text-align: center;
}
.graph-options {
  width: 100%;
  font-weight: bolder;
  font-size: 1.2rem;
  text-align: center;
  height: min-content;
  margin-bottom: 20px;
  width: auto;
}

.pos-nome-atl {
  font-size: 0.7rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scouts-atl {
  width: 40px;
  display: inline-flex;
}
.legend-position {
  opacity: 0.6;
  /* margin-right: 2px; */
  font-size: 0.7rem;
  /* width: 25px; */
  /* text-align: center; */
}
.compative-atl {
  width: 100%;
  height: 72vh;
  overflow-y: auto;
  background-color: #4d4d4d;
  border-radius: 7px;
  position: relative;
  overflow-x: hidden;
}
.align-bt-close-comp {
  position: sticky;
  float: right;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 999;
}

.group-comparative {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 5px;
}
.comparative-legend,
.comparative-legend-2 {
  position: absolute;
  top: 28px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50px);
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: bolder !important;
  color: #4d4d4d;
  background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
}
.comparative-legend {
  background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
}
.comparative-legend-2 {
  color: white;
  background: rgba(108, 40, 148, 1);
  background: -moz-linear-gradient(
    left,
    rgba(108, 40, 148, 1) 0%,
    rgba(102, 40, 248, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(108, 40, 148, 1)),
    color-stop(100%, rgba(102, 40, 248, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(108, 40, 148, 1) 0%,
    rgba(102, 40, 248, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(108, 40, 148, 1) 0%,
    rgba(102, 40, 248, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(108, 40, 148, 1) 0%,
    rgba(102, 40, 248, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(108, 40, 148, 1) 0%,
    rgba(102, 40, 248, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6c2894', endColorstr='#6628f8', GradientType=1 );
}
.comparative-content {
  border-radius: 10px;
  border: 2px solid rgba(0, 255, 255, 1);
  padding: 50px 10px 20px 10px;
  background-color: #333333;
  height: auto;
  overflow-y: auto;
}
.comp-scouts {
  width: 100%;
  padding: 15px 0;
  height: 2rem;
  border-bottom: 1px solid #4d4d4d;
}
.comp-scouts-bar {
  width: 85%;
  display: inline-block;
}

.comp-scouts-label {
  text-align: center;
  font-size: 0.8rem;
  color: white;
}
.comparative-info {
  height: auto;
  text-align: center;
  align-content: center;
  align-items: center;
  vertical-align: middle;
}
.comparative-info .atleta {
  position: relative;
  display: inline-block;
  width: min-content;
}
.comparative-info .atleta .round-jogador-acd {
  position: relative;
}
.comparative-info .atleta .round-team-atl {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
}
.comparative-info .nome,
.comparative-info .posicao {
  width: -webkit-fill-available;
}
.comparative-info .nome {
  font-size: 1.2rem;
}
.comparative-info .posicao,
.comparative-info .posicao .round-pos-atl-3 {
  position: relative;
  display: inline-block;
}
.ajustWidth {
  width: 100%;
}
</style>
