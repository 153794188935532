<template>
  <div class="table-container">
    <table class="table-scouts-analysis">
      <thead>
        <tr>
          <td>{{ state === "home" ? "Mandante" : "Visitante" }}</td>
          <td style="width: 50px">
            <b-tooltip
              label="Conquistado"
              dashed
              position="is-bottom"
              size="is-small"
              type="is-pigeon"
            >
              Conq.
            </b-tooltip>
          </td>
          <td style="width: 50px">
            <b-tooltip
              label="Cedido"
              dashed
              position="is-bottom"
              size="is-small"
              type="is-pigeon"
            >
              Ced.
            </b-tooltip>
          </td>
          <td>{{ state === "home" ? "Visitante" : "Mandante" }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) of matches" :key="`data-row-${i}`">
          <td
            @click="
              handleValue(row, state === 'home' ? 'mandante' : 'visitante')
            "
          >
            <div>
              <b-tooltip
                :label="row[propertyToGetValues.conquistado].nome"
                position="is-right"
                type="is-pigeon"
              >
                <img :src="row[propertyToGetValues.conquistado].escudo" />
              </b-tooltip>
            </div>
          </td>
          <td
            :class="
              getClassByMetric(
                row[propertyToGetValues.conquistado].conquistado,
                'con'
              )
            "
            @click="
              handleValue(row, state === 'home' ? 'mandante' : 'visitante')
            "
          >
            <div>
              {{
                formatValue(row[propertyToGetValues.conquistado].conquistado)
              }}
            </div>
          </td>
          <td
            :class="
              getClassByMetric(row[propertyToGetValues.cedido].cedido, 'ced')
            "
            @click="
              handleValue(row, state !== 'home' ? 'mandante' : 'visitante')
            "
          >
            <div>
              {{ formatValue(row[propertyToGetValues.cedido].cedido) }}
            </div>
          </td>
          <td
            @click="
              handleValue(row, state !== 'home' ? 'mandante' : 'visitante')
            "
          >
            <div>
              <b-tooltip
                :label="row[propertyToGetValues.cedido].nome"
                position="is-left"
                type="is-pigeon"
              >
                <img :src="row[propertyToGetValues.cedido].escudo" />
              </b-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import decimalAdjust from "@/helpers/decimalAdjust.js";

export default {
  name: "TableScouts",
  props: {
    data: {
      type: Array,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    metrics: {
      type: Object,
      required: true,
    },
    resume: {
      type: Boolean,
      default: false,
    },
    typeCard: {
      type: String,
      required: true,
    },
  },
  watch: {
    resume() {
      this.handleMatches();
    },
  },
  data() {
    return {
      matches: [],
    };
  },
  computed: {
    propertyToGetValues() {
      const conquistado = this.state === "home" ? "mandante" : "visitante";
      const cedido = this.state === "home" ? "visitante" : "mandante";

      return { conquistado, cedido };
    },
  },
  methods: {
    formatValue(value) {
      if (this.typeCard === "scout") return value;
      return Math.floor10(value, -1).toFixed(1);
    },
    handleMatches() {
      let data = [...this.data];
      data = this.handleSort(data);

      if (this.resume) {
        this.matches = data.filter((m, i) => i < 4);
      } else {
        this.matches = data;
      }
    },
    handleSort(data) {
      return data.sort(
        (a, b) =>
          b[this.propertyToGetValues.cedido].cedido -
          a[this.propertyToGetValues.cedido].cedido
      );
      // .sort((a, b) => a.diff - b.diff);
    },
    getClassByMetric(value, type) {
      const { highest, lowest } = this.getMetricsByType(type);
      const { percent75, percent50, percent25 } = this.divideMetricsPercentages(
        highest
      );

      if (value >= highest) return "td-percent-100";
      else if (value < highest && value >= percent75) return "td-percent-75";
      else if (value < percent75 && value >= percent50) return "td-percent-50";
      else if (value < percent50 && value >= percent25) return "td-regular";
      else if (value < percent25 && value > lowest) return "td-percent-25";
      else return "td-lowest";
    },
    divideMetricsPercentages(highest) {
      const percent75 = Math.floor(highest * 0.75);
      const percent50 = Math.floor(highest * 0.5);
      const percent25 = Math.floor(highest * 0.25);

      return { percent75, percent50, percent25 };
    },
    getMetricsByType(type) {
      const highest =
        type === "con"
          ? this.metrics.highestValueCon
          : this.metrics.highestValueCed;
      const lowest =
        type === "con"
          ? this.metrics.lowestValueCon
          : this.metrics.lowestValueCed;
      return { highest, lowest };
    },
    handleValue(fixture, choose) {
      this.$emit("click-row", { data: fixture, choose });
    },
  },
  created() {
    decimalAdjust();
    this.handleMatches();
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  margin-top: 1em;
  margin-bottom: 0.5em !important;
  width: 100%;

  .table-scouts-analysis {
    width: 100%;

    thead {
      tr {
        font-size: 0.8em;
        background-color: #34374b;

        td {
          padding: 0.25em 0.1em;
          text-align: center;

          &:last-child {
            border-radius: 0px 7px 7px 0px;
          }
          &:first-child {
            border-radius: 7px 0px 0px 7px;
          }
        }
      }
    }

    tbody {
      tr {
        font-size: 0.9em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.063);

        &:hover {
          transition: all 0.2s ease-in-out;
          background-color: rgba(0, 0, 0, 0.05);
        }

        td {
          cursor: pointer;
          text-align: center;
          align-content: center;
          border-left: 1px solid #34374b;

          .percent-100 {
            background-color: rgb(31, 155, 0) !important;
          }
          .percent-75 {
            background-color: rgb(96, 187, 73) !important;
          }
          .percent-50 {
            background-color: rgb(184, 201, 71) !important;
          }
          .percent-25 {
            background-color: rgb(221, 122, 122) !important;
          }
          .lowest {
            background-color: rgb(221, 48, 48) !important;
          }

          &:first-child {
            border-left: none;
          }

          div {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
