<template>
  <div id="modal-analysis" class="modal-card">
    <div class="card">
      <div class="card-content">
        <div class="card-header-2">
          <div class="level is-mobile" style="margin-bottom: 1em">
            <div class="level-left">
              <h2 class="is-size-5">{{ title }}</h2>
            </div>
            <div class="level-right">
              <b-button
                type="is-magenta"
                icon-right="close"
                size="is-small"
                @click="close"
              ></b-button>
            </div>
          </div>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <div class="card-content">
            <Select
              v-model="form.posicao"
              label="Posição"
              :show-valid="false"
              name="position"
              :rules="{
                required: true,
              }"
            >
              <option v-if="allPositions" value="todos"
                >Todas as Posições</option
              >
              <option
                v-for="position of positions"
                :key="position.abreviacao"
                :value="position.abreviacao"
              >
                {{ position.descricao }}
              </option>
            </Select>

            <div style="margin: 1.2em 0">
              <label class="label">Tipo de Análise</label>
              <div>
                <b-radio v-model="form.tipo" name="type" native-value="scout">
                  Análise por scout
                </b-radio>
                <b-radio
                  v-model="form.tipo"
                  name="type"
                  native-value="pontuacao"
                >
                  Média de pontuação
                </b-radio>
              </div>
            </div>

            <div style="margin-bottom: 1em">
              <Select
                v-if="form.tipo === 'scout'"
                v-model="form.scout"
                label="Escolha de scout"
                :show-valid="false"
                name="scout"
              >
                <option
                  v-for="scout of scoutsOptions"
                  :key="scout.scouts"
                  :value="scout.scouts"
                >
                  {{ scout.descricao }}
                </option>
              </Select>
            </div>

            <div>
              <Select
                v-model="form.jogos"
                label="Filtro de jogos"
                :show-valid="false"
                name="filter"
              >
                <option
                  v-for="filter of filters"
                  :key="filter.slug"
                  :value="filter.slug"
                >
                  {{ filter.label }}
                </option>
              </Select>
            </div>

            <div style="margin-top: 1em">
              <b-radio
                v-model="form.mandoDeJogo"
                name="mando"
                native-value="mando"
              >
                Jogos de acordo com o mando de campo
              </b-radio>
              <b-radio
                v-model="form.mandoDeJogo"
                name="mando"
                native-value="ambos"
              >
                Todos os jogos
              </b-radio>
            </div>
          </div>

          <div class="card-footer">
            <div class="align-buttons">
              <b-button type="is-light" @click="close">Cancelar</b-button>
              <b-button type="is-primary" @click="handleSubmit(result)"
                >Salvar</b-button
              >
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Modal from "@/components/modals/ModalMessage";

export default {
  name: "ModalEdit",
  components: {
    Modal,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    config: {
      type: Object,
      default: undefined,
    },
    positions: {
      type: Array,
      required: true,
    },
    scouts: {
      type: Array,
      required: true,
    },
    allPositions: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filters: [
        { slug: "todos", label: "todos" },
        { slug: "ult_3", label: "últimos 3 jogos" },
        { slug: "ult_5", label: "últimos 5 jogos" },
        { slug: "fase_1", label: "1 turno" },
        { slug: "fase_2", label: "2 turno" },
      ],
      form: {
        posicao: "",
        tipo: "pontuacao",
        mandoDeJogo: "ambos",
        jogos: "ult_3",
      },
    };
  },
  computed: {
    isNew() {
      return this.config ? false : true;
    },
    title() {
      return this.isNew ? "Criar Análise" : "Editar Análise";
    },
    scoutsOptions() {
      return this.handleScouts(this.scouts);
    },
  },
  created() {
    if (!this.isNew) this.form = { ...this.config };
  },
  methods: {
    close() {
      this.form = {
        posicao: "",
        tipo: "pontuacao",
        mandoDeJogo: "ambos",
      };
      this.$emit("close");
    },
    result() {
      let params = {
        ...this.form,
      };

      if (params.tipo === "pontuacao") {
        params.scout = null;
      }

      this.$emit("result", { ...this.form });
      this.close();
    },
    handleScouts(scouts) {
      return scouts
        .map((scout) => {
          if (scout.descricao) return null;

          const normalizedScout = scout.id_scout
            ? scout
            : this.scouts.find((s) => s.cd_scout === scout.scouts);

          return {
            descricao: normalizedScout.ds_scout,
            scouts: normalizedScout.cd_scout,
          };
        })
        .filter((s) => s)
        .sort((a, b) => {
          if (a.descricao < b.descricao) {
            return -1;
          }
          if (a.descricao > b.descricao) {
            return 1;
          }
          return 0;
        });
    },
  },
};
</script>

<style lang="scss">
.modal-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (max-width: 768px) {
    .card {
      height: 100vh !important;
      overflow-y: auto;
    }
  }

  .align-buttons {
    width: 100%;
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
}
</style>
