var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-panel-container",on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('Navbar',{attrs:{"championships":_vm.championships,"is-page-for-default-championship":_vm.isPageForDefaultChampionship},on:{"change-championship":_vm.handleChangeChampionship}}),_c('div',{staticClass:"app-panel-content"},[_c('div',{staticClass:"app-panel-alignment"},[_c('b-loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),(!_vm.loading)?_c('section',{staticClass:"section adjust-section"},[_c('b-message',{staticClass:"message-default-championship",attrs:{"active":_vm.isPageForDefaultChampionship,"type":"is-independece"},on:{"update:active":function($event){_vm.isPageForDefaultChampionship=$event}}},[_c('p',[_vm._v(" Os dados informados nesta tela são apenas do "),_c('b',[_vm._v("campeonato brasileiro")]),_vm._v(". ")])]),_vm._t("default")],2):_vm._e()],1)]),_c('b-notification',{staticClass:"notificao-limited-access",attrs:{"type":"is-independence","active":_vm.isActiveLimitedAccess,"aria-close-label":"Close notification"},on:{"update:active":function($event){_vm.isActiveLimitedAccess=$event}}},[_vm._v(" Sua Assinatura possui um acesso limitado, sem permissão para algumas telas, ou expirando antes do final da temporada. "),_c('router-link',{attrs:{"to":'/renewSignature/' + _vm.userId + '?action=upgrade'}},[_c('b',{staticClass:"has-text-primary"},[_vm._v("Clique Aqui")])]),_vm._v(" para atualizar a Assinatura! ")],1),_c('footer',[_c('div',{staticClass:"app-panel-alignment"},[_c('div',{staticClass:"level"},[_vm._m(0),_c('div',{staticClass:"level-right group-social-medias"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: 'Telegram',
              classes: 'home-tooltip',
            }),expression:"{\n              content: 'Telegram',\n              classes: 'home-tooltip',\n            }",modifiers:{"bottom":true}}],attrs:{"href":"https://t.me/escala10","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"telegram-plane"}})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: 'Whatsapp',
              classes: 'home-tooltip',
            }),expression:"{\n              content: 'Whatsapp',\n              classes: 'home-tooltip',\n            }",modifiers:{"bottom":true}}],attrs:{"href":"https://api.whatsapp.com/send?phone=5521974399122","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"whatsapp"}})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: 'Facebook',
              classes: 'home-tooltip',
            }),expression:"{\n              content: 'Facebook',\n              classes: 'home-tooltip',\n            }",modifiers:{"bottom":true}}],attrs:{"href":"https://www.facebook.com/escala10.br/","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"facebook"}})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: 'Instagram',
              classes: 'home-tooltip',
            }),expression:"{\n              content: 'Instagram',\n              classes: 'home-tooltip',\n            }",modifiers:{"bottom":true}}],attrs:{"href":"https://www.instagram.com/escala10.br/","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"instagram"}})],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"level-left"},[_c('img',{staticClass:"footer-brand",attrs:{"src":"/images/escala10.png","alt":"Escala 10"}}),_c('span',[_vm._v("© Copyright 2019 - 2022 ")])])}]

export { render, staticRenderFns }